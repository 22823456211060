import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { Context } from './Constants';
import { useSelector, useDispatch } from 'react-redux';
import { setAccountBalance } from '../features/accountBalanceSlice';
import { setPreviousGames } from '../features/previousGamesSlice';
import { setMyBets } from '../features/myBetsSlice';
import { addMyBets } from '../features/myBetsSlice';
import Pusher from 'pusher-js';
import explosion_sound from '../assets/sounds/hq-explosion-6288.mp3';
import win_sound from '../assets/sounds/mixkit-winning-chimes-2015.mp3';

import { toast } from 'react-toastify';
import Marquee from "react-fast-marquee";
import Mountain1 from '../assets/images/mountain-1.svg';
import Mountain2 from '../assets/images/mountain-2.svg';
import Mountain3 from '../assets/images/mountain-3.svg';
import Mountain4 from '../assets/images/mountain-4.svg';
import Mountain5 from '../assets/images/mountain-5.svg';

import { Airplane } from './animations/Airplane';
import { AirplaneCrash } from './animations/AirplaneCrash';
import useSound from 'use-sound';

import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer
} from "recharts";


import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";

import { Switch } from "@material-tailwind/react";
import { Radio } from "@material-tailwind/react";

import axios from 'axios';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
axios.defaults.withCredentials = true;



const CrashNew = () => {

    const accountBalance = useSelector((state) => state.accountBalance.value);
    const authentication = useSelector((state) => state.authentication.value);
    const previousGames = useSelector((state) => state.previousGames.value);
    const userId = useSelector((state) => state.userId.value);
    const music = useSelector((state) => state.music.value);
    const volume = useSelector((state) => state.volume.value);
    const gameMode = useSelector((state) => state.gameMode.value);

    const dispatch = useDispatch();

    //context variables



    //local storage items
    const gameCurrency = JSON.parse(localStorage.getItem('gameCurrency'));
    const defaultBetValue = JSON.parse(localStorage.getItem('defaultBetValue'));
    const maximumMultiplier = JSON.parse(localStorage.getItem('maximumMultiplier'));
    const maximumWinAmount = JSON.parse(localStorage.getItem('maximumWinAmount'));
    const minimumBet = JSON.parse(localStorage.getItem('minimumBet'));
    const maximumBet = JSON.parse(localStorage.getItem('maximumBet'));
    const betInputInterval = JSON.parse(localStorage.getItem('betInputInterval'));
    const firstPresetInput = JSON.parse(localStorage.getItem('firstPresetInput'));
    const secondPresetInput = JSON.parse(localStorage.getItem('secondPresetInput'));
    const thirdPresetInput = JSON.parse(localStorage.getItem('thirdPresetInput'));
    const [firstBetAmount, setFirstBetAmount] = useState(100);
    const [secondBetAmount, setSecondBetAmount] = useState(100);
    const [autoplayAmount, setAutoplayAmount] = useState(100);

    useEffect(() => {
        //initilize values after loading
        setFirstBetAmount(() => JSON.parse(localStorage.getItem('firstBetAmount')));
        setSecondBetAmount(() => JSON.parse(localStorage.getItem('secondBetAmount')));
        setAutoplayAmount(() => JSON.parse(localStorage.getItem('autoplayAmount')));

    }, []);





    const [playExplosion, { stopExplosion }] = useSound(explosion_sound, { volume, loop: false, interrupt: true, });
    const [playWinSound, { stopWinSound }] = useSound(win_sound, { volume, loop: false, interrupt: true, });


    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const [websocketsDomain, setWebsocketsDomain] = useState(window.WEBSOCKETS_DOMAIN);
    const [websocketsConnection, setWebsocketsConnection] = useState(window.WEBSOCKETS_CONNECTION);
    const [pusherAppKey, setPusherAppKey] = useState(window.PUSHER_APP_KEY);
    const [data, setData] = useState([]);
    const [airplaneLeft, setAirplaneLeft] = useState(0);
    const [airplaneTop, setAirplaneTop] = useState(0);
    const [roundId, setRoundId] = useState(0);
    const [delay, setDelay] = useState(null);
    const [topDelay, setTopDelay] = useState(null);
    const [coefficient, setCoefficient] = useState(1);
    const [cashoutHash, setCashoutHash] = useState(null);
    const [crashPoint, setCrashPoint] = useState(null);
    const airplaneField = useRef(null);
    const [firstBetMaximumCashoutNotification, setFirstBetMaximumCashoutNotification] = useState(false);
    const [secondBetMaximumCashoutNotification, setSecondBetMaximumCashoutNotification] = useState(false);
    const [firstBetProcessing, setFirstBetProcessing] = useState(false);
    const [secondBetProcessing, setSecondBetProcessing] = useState(false);
    const [autoplayRoundsCounter, setAutoplayRoundsCounter] = useState(0);
    const [autoplayLossValue, setAutoplayLossValue] = useState(0);
    const [maximumStakeAmountUsed, setMaximumStakeAmountUsed] = useState(0);
    const [maximumStakeAmountStatus, setMaximumStakeAmountStatus] = useState(false);
    const [autoplayProcessing, setAutoplayProcessing] = useState(false);
    const [autoplayBetPlaced, setAutoplayBetPlaced] = useState(false);
    const [minimumMultiplier, setMinimumMultiplier] = useState(1.01);
    const [multiplierInputInterval, setMultiplierInputInterval] = useState(0.1);
    const [firstBetRoundId, setFirstBetRoundId] = useState(0);
    const [firstBet, setFirstBet] = useState(0);
    const [firstBetStatus, setFirstBetStatus] = useState(false);
    const [firstBetMultiplier, setFirstBetMultiplier] = useState(2);
    const [firstBetAutoCashout, setFirstBetAutoCashout] = useState(false);
    const [secondBetRoundId, setSecondBetRoundId] = useState(0);
    const [secondBet, setSecondBet] = useState(0);
    const [secondBetStatus, setSecondBetStatus] = useState(false);
    const [secondBetMultiplier, setSecondBetMultiplier] = useState(2);
    const [secondBetAutoCashout, setSecondBetAutoCashout] = useState(false);
    const [autoplayRoundId, setAutoplayRoundId] = useState(0);
    const [maximumStakeAmount, setMaximumStakeAmount] = useState(500000);
    const [autoplay, setAutoplay] = useState(0);
    const [autoplayStatus, setAutoplayStatus] = useState(false);
    const [autoplayMultiplier, setAutoplayMultiplier] = useState(2);
    const [autoplayOnWinIncrease, setAutoplayOnWinIncrease] = useState(false);
    const [autoplayOnLossIncrease, setAutoplayOnLossIncrease] = useState(false);
    const [autoplayOnWinMultiplier, setAutoplayOnWinMultiplier] = useState(2);
    const [autoplayOnLossMultiplier, setAutoplayOnLossMultiplier] = useState(2);
    const [autoplayNumberOfRoundsSwitch, setAutoplayNumberOfRoundsSwitch] = useState(false);
    const [numberOfRoundsInputInterval, setNumberOfRoundsInputInterval] = useState(1);
    const [autoplayNumberOfRoundsValue, setAutoplayNumberOfRoundsValue] = useState(10);
    const [minimumNumberOfRounds, setMinimumNumberOfRounds] = useState(2);
    const [maximumNumberOfRounds, setMaximumNumberOfRounds] = useState(100);
    const [autoplayStopIfLossSwitch, setAutoplayStopIfLossSwitch] = useState(false);
    const [stopIfLossInputInterval, setStopIfLossInputInterval] = useState(100);
    const [autoplayStopIfLossValue, setAutoplayStopIfLossValue] = useState(500000);





    const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }

    const formatMultiplier = (multiplier) => {
        var text1 = "x";
        var num = multiplier;
        let result = text1.concat(num);
        return result;
    }

    const formatCoefficient = (coefficient) => {
        if (coefficient !== null) {
            var text1 = "x";
            var num = parseFloat(coefficient).toFixed(2);
            let result = text1.concat(num);
            return result;
        }
        else {
            return coefficient;
        }
    };
    const formatCrashPoint = (crashPoint) => {
        if (crashPoint !== null) {
            var text1 = "x";
            var num = parseFloat(crashPoint).toFixed(2);
            let result = text1.concat(num);
            return result;
        }
        else {
            return crashPoint;
        }
    };


    const formatAmount = (amount) => {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: gameCurrency,
            currencyDisplay: "narrowSymbol",
        }).format(amount);
    }


    const decrypt = (encryptStr) => {
        return (atob(encryptStr) - 5422);
    }

    useEffect(() => {

        var i = 1;
        var rr = 1;
        var coefficientDifference = 0;
        var endLoop = true;

        var pz = 0;
        var pa = 0;
        var airplane = 1;
        var pv = 0;
        var pvFactor = 0.005;
        var paFactor = 0.005;
        var xAxis = 1;
        var arr = [];
        var loopProgress = false;

        if (websocketsConnection === 'self_hosted') {
            //self hosted websockets
            var pusher = new Pusher(pusherAppKey, {
                broadcaster: 'pusher',
                wsHost: websocketsDomain,
                wsPort: 443,
                forceTLS: true,
                disableStats: true,
                cluster: 'eu',//added this line
                wssPort: 443,
                transports: ['websocket'],
                enabledTransports: ['ws', 'wss'],
            });
        } else if (websocketsConnection === 'local') {
            //local server websockets
            var pusher = new Pusher(pusherAppKey, {
                broadcaster: 'pusher',
                // wsHost: websocketsDomain,
                wsHost: '127.0.0.1',
                wsPort: 6001,
                forceTLS: false,
                disableStats: true,
                cluster: 'eu',//added this line
                wssPort: 6001,
                encrypted: true,
                transports: ['websocket'],
                enabledTransports: ['ws', 'wss'],

            });
        } else {
            // paid pusher configuration
            var pusher = new Pusher(pusherAppKey, {
                cluster: 'eu'
            });
        }



        const fetchData = async () => {

            const channel = pusher.subscribe('coefficientChannel');
            channel.bind('coefficientEvent', function (event) {

                var str = event.coefficient;
                str = str.replace("data: ", "");
                var obj = [];
                str = str.replace(/'/g, '"');
                obj = JSON.parse(str);
                setRoundId(() => obj.roundId);


                if (obj.status === 'coefficient') {
                    if (gameMode == 'slow') {

                        if (obj.coefficient <= 3) {
                            setData((data) => [...data, obj]);
                            var pvPercentage = (obj.pv * 100) / 6.00;
                            var newAirplaneValue = Math.round((airplaneField.current.offsetWidth * obj.airplane) / 100);
                            var newAirplaneHeight = Math.round((airplaneField.current.offsetHeight * pvPercentage) / 100);
                            if (newAirplaneHeight > airplaneField.current.offsetHeight) {
                                newAirplaneHeight = airplaneField.current.offsetHeight;
                            }
                            setAirplaneLeft(() => newAirplaneValue);
                            setAirplaneTop(() => newAirplaneHeight);
                        }

                        setCoefficient((coefficient) => obj.coefficient);
                        setCashoutHash((cashoutHash) => obj.cashout_hash);
                        setDelay((delay) => null);
                        setCrashPoint((crashPoint) => null);
                        setTopDelay((delay) => null);

                    } else {



                        rr = decrypt(obj.rr);
                        coefficientDifference = obj.coefficient - i;
                        var sleep = 130;
                        setCashoutHash((cashoutHash) => obj.cashout_hash);
                        setDelay((delay) => null);
                        setCrashPoint((crashPoint) => null);
                        setTopDelay((delay) => null);

                        if (obj.loop == 'stopper' && i < rr) {
                            endLoop = true;
                            setCoefficient((coefficient) => rr);
                            i = rr;
                            clearTimeout();
                        }

                        if (obj.loop == 'updater' && obj.coefficient < rr && (coefficientDifference < -0.5 || coefficientDifference > 0.5)) {
                            i = obj.coefficient;
                        }

                        if (obj.loop == 'starter' || (obj.loop == 'updater' && loopProgress === false)) {
                            loopProgress = true;
                            endLoop = false;
                            setCoefficient((coefficient) => obj.coefficient);

                            (function myLoop() {

                                setTimeout(function () {

                                    if (airplane < 100) {
                                        airplane = + airplane + 1;
                                        xAxis = + xAxis + 0.01;

                                        pv = + pvFactor + pv;
                                        pvFactor = + pvFactor + 0.001;
                                        pa = + pa + paFactor;
                                        paFactor = + paFactor + 0.001;
                                        pz = + pz + 0.062;

                                        arr = {
                                            xAxis: Number(xAxis).toFixed(2),
                                            pv: Number(pv).toFixed(2),
                                            pa: Number(pa).toFixed(2),
                                            pz: Number(pz).toFixed(2)
                                        };
                                        setData((data) => [...data, arr]);

                                        var pvPercentage = (pv * 100) / 6;
                                        var newAirplaneValue = Math.round((airplaneField.current.offsetWidth * airplane) / 100);
                                        var newAirplaneHeight = Math.round((airplaneField.current.offsetHeight * pvPercentage) / 100);
                                        if (newAirplaneHeight > airplaneField.current.offsetHeight) {
                                            newAirplaneHeight = airplaneField.current.offsetHeight;
                                        }
                                        setAirplaneLeft(() => newAirplaneValue);
                                        setAirplaneTop(() => newAirplaneHeight);
                                    }

                                    if (i < rr) {
                                        if (i < 3) {
                                            i = + i + 0.01;
                                            sleep = 130;
                                        } else if (i < 5) {
                                            i = + i + 0.01;
                                            sleep = 60;
                                        } else if (i < 10) {
                                            i = + i + 0.01;
                                            sleep = 30;
                                        } else if (i < 50) {
                                            i = + i + 0.02;
                                            sleep = 10;
                                        } else if (i < 100) {
                                            i = + i + 0.04;
                                            sleep = 10;
                                        } else if (i < 200) {
                                            i = + i + 0.08;
                                            sleep = 10;
                                        } else if (i < 1000) {
                                            i = + i + 0.11;
                                            sleep = 10;
                                        } else if (i < 10000) {
                                            i = + i + 1.11;
                                            sleep = 10;
                                        } else if (i < 100000) {
                                            i = + i + 11.11;
                                            sleep = 10;
                                        } else {
                                            i = + i + 111.11;
                                            sleep = 10;
                                        }
                                    }


                                    if (i >= rr) {
                                        clearTimeout();
                                        setCoefficient((coefficient) => null);
                                        setCrashPoint((crashPoint) => rr);
                                        setTopDelay((delay) => 10);
                                        i = rr;
                                        endLoop = true;
                                    } else if (i < rr && endLoop === false) {
                                        setCoefficient((coefficient) => i);
                                        myLoop();
                                    }

                                }, sleep)
                            })(i);

                        }
                    }

                } else {
                    endLoop = true;
                    i = obj.coefficient;
                    clearTimeout();


                    if (obj.status === 'delay' && obj.delay >= 6) {

                        setDelay((delay) => null);
                        setCrashPoint((crashPoint) => obj.coefficient);
                        setCoefficient((coefficient) => null);
                        setTopDelay((delay) => obj.delay);

                    } else {

                        setAirplaneLeft(() => 0);
                        setAirplaneTop(() => 0);


                        i = 1;
                        rr = 1;
                        coefficientDifference = 0;
                        pz = 0;
                        pa = 0;
                        airplane = 1;
                        pv = 0;
                        pvFactor = 0.005;
                        paFactor = 0.005;
                        xAxis = 1;
                        arr = [];


                        setData([]);
                        setDelay((delay) => obj.delay);
                        setTopDelay((delay) => null);
                        setCrashPoint((crashPoint) => null);
                        // setCrashSoundEvent(() => true);
                        if (obj.delay <= 1) {
                            endLoop = false;
                        }
                    }
                }
            })


            const PreviousGamesChannel = pusher.subscribe('PreviousGamesChannel');
            PreviousGamesChannel.bind('PreviousGamesEvent', function (data) {
                dispatch(setPreviousGames(data.previousGames))
            })

        }
        fetchData();
    }, [pusherAppKey, setPreviousGames, websocketsConnection, websocketsDomain]);


    //first Bet
    const formatFirstBetAmount = (newBet) => {

        newBet = Number(newBet);

        if (accountBalance < minimumBet) {
            setFirstBetAmount(() => null);
            errorNotify('Insufficient funds! Account Balance: ' + formatAmount(accountBalance));
        } else if (newBet > maximumBet) {
            setFirstBetAmount(() => maximumBet);
            errorNotify('Maximum bet allowed is ' + formatAmount(maximumBet));
        } else if (newBet > accountBalance) {
            setFirstBetAmount(() => accountBalance);
            errorNotify('Insufficient funds! Account Balance: ' + formatAmount(accountBalance));
        } else if (newBet < minimumBet) {
            setFirstBetAmount(() => minimumBet);
            errorNotify('Minimum bet allowed is ' + formatAmount(minimumBet));
        } else {
            setFirstBetAmount(() => newBet);
        }
    }

    const formatFirstBetAutoCashout = (multiplier) => {

        multiplier = Number(multiplier);

        if (multiplier < minimumMultiplier) {
            setFirstBetMultiplier(() => minimumMultiplier);
            errorNotify('Minimum multiplier allowed is ' + formatMultiplier(minimumMultiplier));
        } else if (multiplier > maximumMultiplier) {
            setFirstBetMultiplier(() => maximumMultiplier);
            errorNotify('Maximum multiplier allowed is ' + formatMultiplier(maximumMultiplier));
        } else {
            setFirstBetMultiplier(() => parseFloat(multiplier).toFixed(2));
        }
    }

    //second Bet /
    const formatSecondBetAmount = (newBet) => {

        newBet = Number(newBet);

        if (accountBalance < minimumBet) {
            setSecondBetAmount(() => null);
            errorNotify('Insufficient funds! Account Balance: ' + formatAmount(accountBalance));
        } else if (newBet > maximumBet) {
            setSecondBetAmount(() => maximumBet);
            errorNotify('Maximum bet allowed is ' + formatAmount(maximumBet));
        } else if (newBet > accountBalance) {
            setSecondBetAmount(() => accountBalance);
            errorNotify('Insufficient funds! Account Balance: ' + formatAmount(accountBalance));
        } else if (newBet < minimumBet) {
            setSecondBetAmount(() => minimumBet);
            errorNotify('Minimum bet allowed is ' + formatAmount(minimumBet));
        } else {
            setSecondBetAmount(() => newBet);
        }
    }

    const formatSecondBetAutoCashout = (multiplier) => {

        multiplier = Number(multiplier);

        if (multiplier < minimumMultiplier) {
            setSecondBetMultiplier(() => minimumMultiplier);
            errorNotify('Minimum multiplier allowed is ' + formatMultiplier(minimumMultiplier));
        } else if (multiplier > maximumMultiplier) {
            setSecondBetMultiplier(() => maximumMultiplier);
            errorNotify('Maximum multiplier allowed is ' + formatMultiplier(maximumMultiplier));
        } else {
            setSecondBetMultiplier(() => parseFloat(multiplier).toFixed(2));
        }
    }

    //Autoplay
    const formatAutoplayAmount = (newBet) => {

        newBet = Number(newBet);


        if (accountBalance < minimumBet) {
            setAutoplayAmount(() => null);
            errorNotify('Insufficient funds! Account Balance: ' + formatAmount(accountBalance));
        } else if (newBet > maximumBet) {
            setAutoplayAmount(() => maximumBet);
            errorNotify('Maximum bet allowed is ' + formatAmount(maximumBet));
        } else if (newBet > accountBalance) {
            setAutoplayAmount(() => accountBalance);
            errorNotify('Insufficient funds! Account Balance: ' + formatAmount(accountBalance));
        } else if (newBet < minimumBet) {
            setAutoplayAmount(() => minimumBet);
            errorNotify('Minimum bet allowed is ' + formatAmount(minimumBet));
        } else {
            setAutoplayAmount(() => newBet);
        }
    }

    const formatAutoplayAutoCashout = (multiplier) => {

        multiplier = Number(multiplier);

        if (multiplier < minimumMultiplier) {
            setAutoplayMultiplier(() => minimumMultiplier);
            errorNotify('Minimum multiplier allowed is ' + formatMultiplier(minimumMultiplier));
        } else if (multiplier > maximumMultiplier) {
            setAutoplayMultiplier(() => maximumMultiplier);
            errorNotify('Maximum multiplier allowed is ' + formatMultiplier(maximumMultiplier));
        } else {
            setAutoplayMultiplier(() => parseFloat(multiplier).toFixed(2));
        }
    }

    const formatAutoplayOnWinMultiplier = (multiplier) => {

        multiplier = Number(multiplier);

        if (multiplier < minimumMultiplier) {
            setAutoplayOnWinMultiplier(() => minimumMultiplier);
            errorNotify('Minimum multiplier allowed is ' + formatMultiplier(minimumMultiplier));
        } else if (multiplier > maximumMultiplier) {
            setAutoplayOnWinMultiplier(() => maximumMultiplier);
            errorNotify('Maximum multiplier allowed is ' + formatMultiplier(maximumMultiplier));
        } else {
            setAutoplayOnWinMultiplier(() => parseFloat(multiplier).toFixed(1));
        }
    }

    const formatAutoplayOnLossMultiplier = (multiplier) => {

        multiplier = Number(multiplier);

        if (multiplier < minimumMultiplier) {
            setAutoplayOnLossMultiplier(() => minimumMultiplier);
            errorNotify('Minimum multiplier allowed is ' + formatMultiplier(minimumMultiplier));
        } else if (multiplier > maximumMultiplier) {
            setAutoplayOnLossMultiplier(() => maximumMultiplier);
            errorNotify('Maximum multiplier allowed is ' + formatMultiplier(maximumMultiplier));
        } else {
            setAutoplayOnLossMultiplier(() => parseFloat(multiplier).toFixed(1));
        }
    }

    const formatAutoplayNumberOfRounds = (value) => {

        value = Number(value);

        if (value < minimumNumberOfRounds) {
            setAutoplayNumberOfRoundsValue(() => minimumNumberOfRounds);
            errorNotify('Minimum number of rounds allowed is ' + minimumNumberOfRounds);
        } else if (value > maximumNumberOfRounds) {
            setAutoplayNumberOfRoundsValue(() => maximumNumberOfRounds);
            errorNotify('Maximum number of rounds allowed is ' + maximumNumberOfRounds);
        } else {
            setAutoplayNumberOfRoundsValue(() => value);
        }
    }

    const formatAutoplayStopIfLoss = (value) => {

        value = Number(value);

        if (value < minimumBet) {
            setAutoplayStopIfLossValue(() => minimumBet);
            errorNotify('Minimum amount allowed is ' + minimumBet);
        } else {
            setAutoplayStopIfLossValue(() => value);
        }
    }

    const formatMaximumStakeAmount = (value) => {

        value = Number(value);

        if (value < minimumBet) {
            setMaximumStakeAmount(() => minimumBet);
            errorNotify('Minimum amount allowed is ' + minimumBet);
        } else {
            setMaximumStakeAmount(() => value);
        }
    }


    const checkWidthHeight = () => {
        const element = document.getElementById("airplaneField");
        let text = "Height including padding and border: " + element.offsetHeight + "px<br>";
        text += "Width including padding and border: " + element.offsetWidth + "px";
    }



    const [openRoundHistory, setOpenRoundHistory] = React.useState(false);
    const toggleOpen = () => setOpenRoundHistory((cur) => !cur);



    //reset bet incase the user fails to cashout
    useEffect(() => {
        if ((firstBetProcessing === false) && (crashPoint !== null) && (roundId === firstBetRoundId) && (firstBet > 0)) {
            //    setFirstBetAmount(() => defaultBetValue);
            setFirstBetRoundId(() => 0);
            warnNotify('Bet of ' + formatAmount(firstBetAmount) + ' lost!');
            setFirstBet(() => 0);
            setFirstBetStatus(() => false);
            setFirstBetMaximumCashoutNotification(() => false);
            if (music === true) {
                playExplosion();
            }
        }
    }, [crashPoint, firstBet, firstBetAmount, firstBetProcessing, firstBetRoundId, roundId]);

    //Set cashout amount 
    useEffect(() => {
        if ((firstBetRoundId > 0) && (roundId === firstBetRoundId)) {
            var newFirstBetAmountValue = firstBetAmount * coefficient;
            if (newFirstBetAmountValue < maximumWinAmount) {
                setFirstBet(() => newFirstBetAmountValue);
            } else {
                setFirstBet(() => maximumWinAmount);
            }
        }
    }, [coefficient, firstBetAmount, firstBetRoundId, maximumWinAmount, roundId]);



    //execute autocashout
    useEffect(() => {
        if ((firstBetAutoCashout === true) && (coefficient >= firstBetMultiplier) && (firstBetRoundId > 0) && (roundId === firstBetRoundId)) {
            var newAutoCashoutValue = firstBetMultiplier * firstBetAmount;
            if (newAutoCashoutValue > maximumWinAmount) {
                newAutoCashoutValue = maximumWinAmount;
            }

            if (authentication === false) {
                setFirstBetAutoCashout(() => false);
                successNotify('Congratulations! You have won ' + formatAmount(newAutoCashoutValue));
                if (music === true) {
                    playWinSound();
                }
                var newAccountBalance = parseFloat(accountBalance) + parseFloat(newAutoCashoutValue);
                dispatch(setAccountBalance(newAccountBalance))
                setFirstBetRoundId(() => 0);
                setFirstBet(() => 0);
                setFirstBetMaximumCashoutNotification(() => false);
                setFirstBetStatus(() => false);
            } else {
                setFirstBetAutoCashout(() => false);
                setFirstBetProcessing(() => true);
                axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                axios.post(`${baseUrl}/api/cashoutBet`,
                    { autoCashout: true, userId: userId, type: 'first_bet', coefficient_id: roundId, cashout_hash: cashoutHash, cashout: coefficient },
                    {
                        headers: {
                            'Accept': 'application/vnd.api+json',
                            'Content-Type': 'application/vnd.api+json',
                            'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                        },
                    }
                ).then((response) => {
                    dispatch(setAccountBalance(response.data.data.new_account_balance));
                    dispatch(setMyBets(response.data.data.myBets));
                    dispatch(addMyBets(response?.data.data.bet));
                    //   setFirstBetAmount(() => defaultBetValue);
                    setFirstBetRoundId(() => 0);
                    setFirstBet(() => 0);
                    setFirstBetMaximumCashoutNotification(() => false);
                    setFirstBetStatus(() => false);
                    setFirstBetAutoCashout(() => false);
                    successNotify('Congratulations! You have won ' + formatAmount(response.data.data.bet.win_amount));
                    if (music === true) {
                        playWinSound();
                    }
                    setFirstBetProcessing(() => false);
                }).catch((error) => {
                    if (!error?.response) {
                        errorNotify('No Server Response');
                    } else {
                        //    errorNotify(error.message);
                        errorNotify('Cashout Failed! ' + error.response.data.message);

                        //   setFirstBetAmount(() => defaultBetValue);
                        setFirstBetRoundId(() => 0);
                        setFirstBet(() => 0);
                        setFirstBetMaximumCashoutNotification(() => false);
                        setFirstBetStatus(() => false);
                        setFirstBetAutoCashout(() => false);
                        setFirstBetProcessing(() => false);


                    }
                    setFirstBetAutoCashout(() => true);
                    setFirstBetProcessing(() => false);
                    if ((firstBetProcessing === false) && (crashPoint !== null) && (roundId === firstBetRoundId) && (firstBet > 0)) {
                        //   setFirstBetAmount(() => defaultBetValue);
                        setFirstBetRoundId(() => 0);
                        warnNotify('Bet of ' + formatAmount(firstBetAmount) + ' lost!');
                        setFirstBet(() => 0);
                        setFirstBetStatus(() => false);
                        setFirstBetMaximumCashoutNotification(() => false);
                        if (music === true) {
                            playExplosion();
                        }

                    }

                });
            }
        }
    }, [authentication, baseUrl, cashoutHash, coefficient, crashPoint, dispatch, firstBet, firstBetAmount, firstBetAutoCashout, firstBetMultiplier, firstBetProcessing, firstBetRoundId, maximumWinAmount, roundId, setMyBets, userId]);

    //Inform user to cashout if firstBetAmount exceeds the maximum win allowed
    useEffect(() => {
        if ((firstBet === maximumWinAmount) && (firstBetMaximumCashoutNotification === false)) {
            if (firstBetAutoCashout === false) {
                // warnNotify('Maximum win amount of ' + formatAmount(maximumWinAmount) + ' reached! Click to Cashout.');
                setFirstBetMaximumCashoutNotification(() => true);
            } else {
                // warnNotify('Maximum win amount of ' + formatAmount(maximumWinAmount) + ' reached!');
                //execute autocashout if true
                if (firstBetAutoCashout === true) {
                    var newAutoCashoutValue = firstBetMultiplier * firstBetAmount;
                    if (newAutoCashoutValue > maximumWinAmount) {
                        newAutoCashoutValue = maximumWinAmount;
                    }

                    if (authentication === false) {
                        setFirstBetAutoCashout(() => false);
                        successNotify('Congratulations! You have won ' + formatAmount(newAutoCashoutValue));
                        if (music === true) {
                            playWinSound();
                        }
                        var newAccountBalance = parseFloat(accountBalance) + parseFloat(newAutoCashoutValue);
                        dispatch(setAccountBalance(newAccountBalance));
                        //         setFirstBetAmount(() => defaultBetValue);
                        setFirstBetRoundId(() => 0);
                        setFirstBet(() => 0);
                        setFirstBetMaximumCashoutNotification(() => false);
                        setFirstBetStatus(() => false);
                        setFirstBetAutoCashout(() => false);
                    } else {
                        setFirstBetAutoCashout(() => false);
                        setFirstBetProcessing(() => true);
                        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                        axios.post(`${baseUrl}/api/cashoutBet`,
                            { autoCashout: true, userId: userId, type: 'first_bet', coefficient_id: roundId, cashout_hash: cashoutHash, cashout: coefficient },
                            {
                                headers: {
                                    'Accept': 'application/vnd.api+json',
                                    'Content-Type': 'application/vnd.api+json',
                                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                                },
                            }
                        ).then((response) => {
                            dispatch(setAccountBalance(response.data.data.new_account_balance));
                            dispatch(setMyBets(response.data.data.myBets));
                            dispatch(addMyBets(response?.data.data.bet));

                            //       setFirstBetAmount(() => defaultBetValue);
                            setFirstBetRoundId(() => 0);
                            setFirstBet(() => 0);
                            setFirstBetMaximumCashoutNotification(() => false);
                            setFirstBetStatus(() => false);
                            setFirstBetAutoCashout(() => false);
                            successNotify('Congratulations! You have won ' + formatAmount(response.data.data.bet.win_amount));
                            if (music === true) {
                                playWinSound();
                            }
                            setFirstBetProcessing(() => false);
                        }).catch((error) => {
                            if (!error?.response) {
                                errorNotify('No Server Response');
                            } else {
                                //  errorNotify(error.message);
                                errorNotify('Cashout Failed! ' + error.response.data.message);
                                ///       setFirstBetAmount(() => defaultBetValue);
                                setFirstBetRoundId(() => 0);
                                setFirstBet(() => 0);
                                setFirstBetMaximumCashoutNotification(() => false);
                                setFirstBetStatus(() => false);
                                setFirstBetAutoCashout(() => false);
                                setFirstBetProcessing(() => false);


                            }
                            setFirstBetAutoCashout(() => true);
                            setFirstBetProcessing(() => false);
                            if ((firstBetProcessing === false) && (crashPoint !== null) && (roundId === firstBetRoundId) && (firstBet > 0)) {
                                //     setFirstBetAmount(() => defaultBetValue);
                                setFirstBetRoundId(() => 0);
                                warnNotify('Bet of ' + formatAmount(firstBetAmount) + ' lost!');
                                setFirstBet(() => 0);
                                setFirstBetStatus(() => false);
                                setFirstBetMaximumCashoutNotification(() => false);
                                if (music === true) {
                                    playExplosion();
                                }

                            }

                        });
                    }


                }
            }
        }
    }, [authentication, baseUrl, cashoutHash, coefficient, crashPoint, dispatch, firstBet, firstBetAmount, firstBetAutoCashout, firstBetMaximumCashoutNotification, firstBetMultiplier, firstBetProcessing, firstBetRoundId, maximumWinAmount, roundId, setMyBets, userId]);



    const handleFirstBetAutoCashout = () => {
        if (firstBetStatus === false) {
            if (firstBetAutoCashout === false) {
                setFirstBetAutoCashout(() => true);
            } else {
                setFirstBetAutoCashout(() => false);
            }
        }
    };

    const handleFirstBetButtonClick = () => {
        if (accountBalance >= firstBetAmount) {
            if (firstBetAmount >= minimumBet) {
                if (authentication == false) {
                    var newAccountBalance = accountBalance - firstBetAmount;
                    dispatch(setAccountBalance(newAccountBalance));
                    setFirstBet(() => firstBetAmount);
                    setFirstBetRoundId(() => parseFloat(roundId) + 1);
                    setFirstBetStatus(() => true);
                    infoNotify('Bet of ' + formatAmount(firstBetAmount) + ' placed successfully');
                } else {
                    setFirstBetProcessing(() => true);
                    axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                    axios.post(`${baseUrl}/api/placeBet`,
                        { userId: userId, amount: firstBetAmount, type: 'first_bet', coefficient_id: parseFloat(roundId) + 1 },
                        {
                            headers: {
                                'Accept': 'application/vnd.api+json',
                                'Content-Type': 'application/vnd.api+json',
                                'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                            },
                        }
                    ).then((response) => {
                        dispatch(setAccountBalance(response.data.data.new_account_balance));
                        setFirstBet(() => response.data.data.bet.amount);
                        setFirstBetRoundId(() => response.data.data.bet.coefficient_id);
                        setFirstBetStatus(() => true);
                        dispatch(setMyBets(response.data.data.myBets));
                        dispatch(addMyBets(response?.data.data.bet));

                        infoNotify('Bet of ' + formatAmount(response.data.data.bet.amount) + ' placed successfully' + response.data.data.message);
                        setFirstBetProcessing(() => false);
                    }).catch((error) => {
                        if (!error?.response) {
                            errorNotify('No Server Response');
                        } else {
                            errorNotify(error.message);
                        }
                        setFirstBetProcessing(() => false);
                    });
                }

            } else {
                errorNotify('Insufficient funds! Minimum Bet allowed is: ' + formatAmount(minimumBet));
            }
        } else {
            errorNotify('Insufficient funds! Account Balance: ' + formatAmount(accountBalance));
        }
    };
    const handleFirstBetButtonCancel = () => {
        if (firstBetProcessing === false) {
            if (authentication === false) {
                var newAccountBalance = parseFloat(accountBalance) + parseFloat(firstBet);
                dispatch(setAccountBalance(newAccountBalance));
                setFirstBetRoundId(() => 0);
                warnNotify('Bet of ' + formatAmount(firstBet) + ' has been cancelled');
                setFirstBet(() => 0);
                setFirstBetStatus(() => false);
            } else {
                setFirstBetProcessing(() => true);
                axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                axios.post(`${baseUrl}/api/cancelBet`,
                    { userId: userId, type: 'first_bet', coefficient_id: parseFloat(roundId) + 1 },
                    {
                        headers: {
                            'Accept': 'application/vnd.api+json',
                            'Content-Type': 'application/vnd.api+json',
                            'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                        },
                    }
                ).then((response) => {
                    dispatch(setAccountBalance(response.data.data.new_account_balance));
                    setFirstBetRoundId(() => 0);
                    setFirstBet(() => 0);
                    setFirstBetStatus(() => false);
                    dispatch(setMyBets(response.data.data.myBets));
                    dispatch(addMyBets(response?.data.data.bet));
                    infoNotify('Bet of ' + formatAmount(response.data.data.bet.amount) + ' has been cancelled');
                    setFirstBetProcessing(() => false);
                }).catch((error) => {
                    if (!error?.response) {
                        errorNotify('No Server Response');
                    } else {
                        errorNotify(error.message);
                    }
                    setFirstBetProcessing(() => false);

                    if (firstBetRoundId < roundId) {
                        setFirstBetRoundId(() => 0);
                        setFirstBet(() => 0);
                        setFirstBetStatus(() => false);
                    }

                });
            }
        }
    };
    const handleFirstBetButtonCashout = () => {
        if (firstBetProcessing === false) {
            if (firstBet > maximumWinAmount) {
                setFirstBet(() => maximumWinAmount);
            }

            if (authentication === false) {
                successNotify('Congratulations! You have won ' + formatAmount(firstBet));
                if (music === true) {
                    playWinSound();
                }
                var newAccountBalance = parseFloat(accountBalance) + parseFloat(firstBet);
                dispatch(setAccountBalance(newAccountBalance));
                //    setFirstBetAmount(() => defaultBetValue);
                setFirstBetRoundId(() => 0);
                setFirstBet(() => 0);
                setFirstBetMaximumCashoutNotification(() => false);
                setFirstBetStatus(() => false);
            } else {
                setFirstBetProcessing(() => true);
                axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                axios.post(`${baseUrl}/api/cashoutBet`,
                    { autoCashout: false, userId: userId, type: 'first_bet', coefficient_id: roundId, cashout_hash: cashoutHash, cashout: coefficient },
                    {
                        headers: {
                            'Accept': 'application/vnd.api+json',
                            'Content-Type': 'application/vnd.api+json',
                            'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                        },
                    }
                ).then((response) => {
                    dispatch(setAccountBalance(response.data.data.new_account_balance));
                    dispatch(setMyBets(response.data.data.myBets));
                    dispatch(addMyBets(response?.data.data.bet));
                    setFirstBetRoundId(() => 0);
                    setFirstBet(() => 0);
                    setFirstBetMaximumCashoutNotification(() => false);
                    setFirstBetStatus(() => false);
                    successNotify('Congratulations! You have won ' + formatAmount(response.data.data.bet.win_amount));
                    if (music === true) {
                        playWinSound();
                    }
                    setFirstBetProcessing(() => false);
                }).catch((error) => {
                    if (!error?.response) {
                        errorNotify('No Server Response');
                    } else {
                        //  errorNotify(error.message);
                        errorNotify('Cashout Failed! ' + error.response.data.message);

                        //    setFirstBetAmount(() => defaultBetValue);
                        setFirstBetRoundId(() => 0);
                        setFirstBet(() => 0);
                        setFirstBetMaximumCashoutNotification(() => false);
                        setFirstBetStatus(() => false);
                        setFirstBetProcessing(() => false);


                    }
                    setFirstBetProcessing(() => false);
                    if ((firstBetProcessing === false) && (crashPoint !== null) && (roundId === firstBetRoundId) && (firstBet > 0)) {
                        //    setFirstBetAmount(() => defaultBetValue);
                        setFirstBetRoundId(() => 0);
                        warnNotify('Bet of ' + formatAmount(firstBetAmount) + ' lost!');
                        setFirstBet(() => 0);
                        setFirstBetStatus(() => false);
                        setFirstBetMaximumCashoutNotification(() => false);
                        if (music === true) {
                            playExplosion();
                        }

                    }

                });
            }
        }

    }




    const handleFirstBetAmountChange = (event) => {
        setFirstBetAmount(() => event.target.value);
    };

    const verifyFirstBetAmountChange = (event) => {
        var newBet = event.target.value;
        setFirstBetAmount(() => newBet);
        formatFirstBetAmount(newBet);
    };

    const handleFirstBetMultiplierChange = (event) => {
        setFirstBetMultiplier(() => event.target.value);
    };

    const verifyFirstBetMultiplierChange = (event) => {
        var newMultiplier = event.target.value;
        setFirstBetMultiplier(() => newMultiplier);
        formatFirstBetAutoCashout(newMultiplier);
    };



    const firstBetReset = () => {
        setFirstBetAmount(() => defaultBetValue);
        setFirstBetMultiplier(() => 2);
        setFirstBetAutoCashout(() => false);
    };

    const firstBetDivideBet = () => {
        var newBet = firstBetAmount / 2;
        setFirstBetAmount(() => newBet);
        formatFirstBetAmount(newBet);
    };

    const firstBetMultiplyBet = () => {
        var newBet = firstBetAmount * 2;
        setFirstBetAmount(() => newBet);
        formatFirstBetAmount(newBet);
    };

    const firstBetSubtractBet = () => {
        var newBet = firstBetAmount - betInputInterval;
        setFirstBetAmount(() => newBet);
        formatFirstBetAmount(newBet);
    };

    const firstBetAddBet = () => {
        var newBet = parseFloat(firstBetAmount) + parseFloat(betInputInterval);
        setFirstBetAmount(() => newBet);
        formatFirstBetAmount(newBet);
    };
    const firstBetSetAddValue = (value) => {
        var newBet = parseFloat(value.currentTarget.getAttribute("data-value"));
        setFirstBetAmount(() => newBet);
        formatFirstBetAmount(newBet);
    };

    const firstBetSetMinBet = () => {
        var newBet = minimumBet;
        setFirstBetAmount(() => newBet);
        formatFirstBetAmount(newBet);
    };
    const firstBetSetMaxBet = () => {
        var newBet = maximumBet;
        setFirstBetAmount(() => newBet);
        formatFirstBetAmount(newBet);
    };
    const firstBetAllInBet = () => {
        var newBet = accountBalance;
        setFirstBetAmount(() => newBet);
        formatFirstBetAmount(newBet);
    };


    const firstBetSubtractMultiplier = () => {
        var newMultiplier = firstBetMultiplier - multiplierInputInterval;
        setFirstBetMultiplier(() => newMultiplier);
        formatFirstBetAutoCashout(newMultiplier);
    };

    const firstBetAddMultiplier = () => {
        var newMultiplier = parseFloat(firstBetMultiplier) + parseFloat(multiplierInputInterval);
        setFirstBetMultiplier(() => newMultiplier);
        formatFirstBetAutoCashout(newMultiplier);
    };






    //reset bet incase the user fails to cashout
    useEffect(() => {
        if ((secondBetProcessing === false) && (crashPoint !== null) && (roundId === secondBetRoundId) && (secondBet > 0)) {
            //   setSecondBetAmount(() => defaultBetValue);
            setSecondBetRoundId(() => 0);
            warnNotify('Bet of ' + formatAmount(secondBetAmount) + ' lost!');
            setSecondBet(() => 0);
            setSecondBetStatus(() => false);
            setSecondBetMaximumCashoutNotification(() => false);
            if (music === true) {
                playExplosion();
            }

        }
    }, [crashPoint, roundId, secondBet, secondBetAmount, secondBetProcessing, secondBetRoundId]);

    //Set cashout amount 
    useEffect(() => {
        if ((secondBetRoundId > 0) && (roundId === secondBetRoundId)) {
            var newSecondBetAmountValue = secondBetAmount * coefficient;
            if (newSecondBetAmountValue < maximumWinAmount) {
                setSecondBet(() => newSecondBetAmountValue);
            } else {
                setSecondBet(() => maximumWinAmount);
            }
        }
    }, [coefficient, maximumWinAmount, roundId, secondBetAmount, secondBetRoundId]);

    //execute autocashout
    useEffect(() => {
        if ((secondBetAutoCashout === true) && (coefficient >= secondBetMultiplier) && (secondBetRoundId > 0) && (roundId === secondBetRoundId)) {
            var newAutoCashoutValue = secondBetMultiplier * secondBetAmount;
            if (newAutoCashoutValue > maximumWinAmount) {
                newAutoCashoutValue = maximumWinAmount;
            }

            if (authentication === false) {
                setSecondBetAutoCashout(() => false);
                successNotify('Congratulations! You have won ' + formatAmount(newAutoCashoutValue));
                if (music === true) {
                    playWinSound();
                }
                var newAccountBalance = parseFloat(accountBalance) + parseFloat(newAutoCashoutValue);
                dispatch(setAccountBalance(newAccountBalance));
                //   setSecondBetAmount(() => defaultBetValue);
                setSecondBetRoundId(() => 0);
                setSecondBet(() => 0);
                setSecondBetMaximumCashoutNotification(() => false);
                setSecondBetStatus(() => false);
                setSecondBetAutoCashout(() => false);
            } else {
                setSecondBetAutoCashout(() => false);
                setSecondBetProcessing(() => true);
                axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                axios.post(`${baseUrl}/api/cashoutBet`,
                    { autoCashout: true, userId: userId, type: 'second_bet', coefficient_id: roundId, cashout_hash: cashoutHash, cashout: coefficient },
                    {
                        headers: {
                            'Accept': 'application/vnd.api+json',
                            'Content-Type': 'application/vnd.api+json',
                            'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                        },
                    }
                ).then((response) => {
                    dispatch(setAccountBalance(response.data.data.new_account_balance));
                    dispatch(setMyBets(response.data.data.myBets));
                    dispatch(addMyBets(response?.data.data.bet));
                    setSecondBetRoundId(() => 0);
                    setSecondBet(() => 0);
                    setSecondBetMaximumCashoutNotification(() => false);
                    setSecondBetStatus(() => false);
                    setSecondBetAutoCashout(() => false);
                    successNotify('Congratulations! You have won ' + formatAmount(response.data.data.bet.win_amount));
                    if (music === true) {
                        playWinSound();
                    }
                    setSecondBetProcessing(() => false);
                }).catch((error) => {
                    if (!error?.response) {
                        errorNotify('No Server Response');
                    } else {
                        //   errorNotify(error.message);
                        errorNotify('Cashout Failed! ' + error.response.data.message);
                        //    setSecondBetAmount(() => defaultBetValue);
                        setSecondBetRoundId(() => 0);
                        setSecondBet(() => 0);
                        setSecondBetMaximumCashoutNotification(() => false);
                        setSecondBetStatus(() => false);
                        setSecondBetAutoCashout(() => false);
                        setSecondBetProcessing(() => false);


                    }
                    setSecondBetAutoCashout(() => true);
                    setSecondBetProcessing(() => false);
                    if ((secondBetProcessing === false) && (crashPoint !== null) && (roundId === secondBetRoundId) && (secondBet > 0)) {
                        //   setSecondBetAmount(() => defaultBetValue);
                        setSecondBetRoundId(() => 0);
                        warnNotify('Bet of ' + formatAmount(secondBetAmount) + ' lost!');
                        setSecondBet(() => 0);
                        setSecondBetStatus(() => false);
                        setSecondBetMaximumCashoutNotification(() => false);
                        if (music === true) {
                            playExplosion();
                        }

                    }

                });
            }
        }
    }, [authentication, baseUrl, cashoutHash, coefficient, crashPoint, dispatch, maximumWinAmount, roundId, secondBet, secondBetAmount, secondBetAutoCashout, secondBetMultiplier, secondBetProcessing, secondBetRoundId, setMyBets, userId]);

    //Inform user to cashout if secondBetAmount exceeds the maximum win allowed
    useEffect(() => {
        if ((secondBet === maximumWinAmount) && (secondBetMaximumCashoutNotification === false)) {
            if (secondBetAutoCashout === false) {
                // warnNotify('Maximum win amount of ' + formatAmount(maximumWinAmount) + ' reached! Click to Cashout.');
                setSecondBetMaximumCashoutNotification(() => true);
            } else {
                // warnNotify('Maximum win amount of ' + formatAmount(maximumWinAmount) + ' reached!');
                //execute autocashout if true
                if (secondBetAutoCashout === true) {
                    var newAutoCashoutValue = secondBetMultiplier * secondBetAmount;
                    if (newAutoCashoutValue > maximumWinAmount) {
                        newAutoCashoutValue = maximumWinAmount;
                    }


                    if (authentication === false) {
                        setSecondBetAutoCashout(() => false);
                        successNotify('Congratulations! You have won ' + formatAmount(newAutoCashoutValue));
                        if (music === true) {
                            playWinSound();
                        }
                        var newAccountBalance = parseFloat(accountBalance) + parseFloat(newAutoCashoutValue);
                        dispatch(setAccountBalance(newAccountBalance));
                        //   setSecondBetAmount(() => defaultBetValue);
                        setSecondBetRoundId(() => 0);
                        setSecondBet(() => 0);
                        setSecondBetMaximumCashoutNotification(() => false);
                        setSecondBetStatus(() => false);
                        setSecondBetAutoCashout(() => false);
                    } else {
                        setSecondBetAutoCashout(() => false);
                        setSecondBetProcessing(() => true);
                        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                        axios.post(`${baseUrl}/api/cashoutBet`,
                            { autoCashout: true, userId: userId, type: 'second_bet', coefficient_id: roundId, cashout_hash: cashoutHash, cashout: coefficient },
                            {
                                headers: {
                                    'Accept': 'application/vnd.api+json',
                                    'Content-Type': 'application/vnd.api+json',
                                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                                },
                            }
                        ).then((response) => {
                            dispatch(setAccountBalance(response.data.data.new_account_balance));
                            dispatch(setMyBets(response.data.data.myBets));
                            dispatch(addMyBets(response?.data.data.bet));
                            setSecondBetRoundId(() => 0);
                            setSecondBet(() => 0);
                            setSecondBetMaximumCashoutNotification(() => false);
                            setSecondBetStatus(() => false);
                            setSecondBetAutoCashout(() => false);
                            successNotify('Congratulations! You have won ' + formatAmount(response.data.data.bet.win_amount));
                            if (music === true) {
                                playWinSound();
                            }
                            setSecondBetProcessing(() => false);
                        }).catch((error) => {
                            if (!error?.response) {
                                errorNotify('No Server Response');
                            } else {
                                //    errorNotify(error.message);
                                errorNotify('Cashout Failed! ' + error.response.data.message);
                                //   setSecondBetAmount(() => defaultBetValue);
                                setSecondBetRoundId(() => 0);
                                setSecondBet(() => 0);
                                setSecondBetMaximumCashoutNotification(() => false);
                                setSecondBetStatus(() => false);
                                setSecondBetAutoCashout(() => false);
                                setSecondBetProcessing(() => false);


                            }
                            setSecondBetAutoCashout(() => true);
                            setSecondBetProcessing(() => false);
                            if ((secondBetProcessing === false) && (crashPoint !== null) && (roundId === secondBetRoundId) && (secondBet > 0)) {
                                //    setSecondBetAmount(() => defaultBetValue);
                                setSecondBetRoundId(() => 0);
                                warnNotify('Bet of ' + formatAmount(secondBetAmount) + ' lost!');
                                setSecondBet(() => 0);
                                setSecondBetStatus(() => false);
                                setSecondBetMaximumCashoutNotification(() => false);
                                if (music === true) {
                                    playExplosion();
                                }

                            }

                        });
                    }

                }
            }
        }
    }, [authentication, baseUrl, cashoutHash, coefficient, crashPoint, dispatch, maximumWinAmount, roundId, secondBet, secondBetAmount, secondBetAutoCashout, secondBetMaximumCashoutNotification, secondBetMultiplier, secondBetProcessing, secondBetRoundId, setMyBets, userId]);

    const handleSecondBetAutoCashout = () => {
        if (secondBetStatus === false) {
            if (secondBetAutoCashout === false) {
                setSecondBetAutoCashout(() => true);
            } else {
                setSecondBetAutoCashout(() => false);
            }
        }
    };

    const handleSecondBetButtonClick = () => {
        if (accountBalance >= secondBetAmount) {
            if (secondBetAmount >= minimumBet) {

                if (authentication === false) {
                    var newAccountBalance = accountBalance - secondBetAmount;
                    dispatch(setAccountBalance(newAccountBalance));
                    setSecondBet(() => secondBetAmount);
                    setSecondBetRoundId(() => parseFloat(roundId) + 1);
                    setSecondBetStatus(() => true);
                    infoNotify('Bet of ' + formatAmount(secondBetAmount) + ' placed successfully');
                } else {
                    setSecondBetProcessing(() => true);
                    axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                    axios.post(`${baseUrl}/api/placeBet`,
                        { userId: userId, amount: secondBetAmount, type: 'second_bet', coefficient_id: parseFloat(roundId) + 1 },
                        {
                            headers: {
                                'Accept': 'application/vnd.api+json',
                                'Content-Type': 'application/vnd.api+json',
                                'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                            },
                        }
                    ).then((response) => {
                        dispatch(setAccountBalance(response.data.data.new_account_balance));
                        setSecondBet(() => response.data.data.bet.amount);
                        setSecondBetRoundId(() => response.data.data.bet.coefficient_id);
                        setSecondBetStatus(() => true);
                        dispatch(setMyBets(response.data.data.myBets));
                        dispatch(addMyBets(response?.data.data.bet));
                        infoNotify('Bet of ' + formatAmount(response.data.data.bet.amount) + ' placed successfully' + response.data.data.message);
                        setSecondBetProcessing(() => false);
                    }).catch((error) => {
                        if (!error?.response) {
                            errorNotify('No Server Response');
                        } else {
                            errorNotify(error.message);
                        }
                        setSecondBetProcessing(() => false);
                    });
                }

            } else {
                errorNotify('Insufficient funds! Minimum Bet allowed is: ' + formatAmount(minimumBet));
            }
        } else {
            errorNotify('Insufficient funds! Account Balance: ' + formatAmount(accountBalance));
        }

    };
    const handleSecondBetButtonCancel = () => {
        if (secondBetProcessing === false) {
            if (authentication === false) {
                var newAccountBalance = parseFloat(accountBalance) + parseFloat(secondBet);
                dispatch(setAccountBalance(newAccountBalance));
                setSecondBetRoundId(() => 0);
                warnNotify('Bet of ' + formatAmount(secondBet) + ' has been cancelled');
                setSecondBet(() => 0);
                setSecondBetStatus(() => false);
            } else {
                setSecondBetProcessing(() => true);
                axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                axios.post(`${baseUrl}/api/cancelBet`,
                    { userId: userId, type: 'second_bet', coefficient_id: parseFloat(roundId) + 1 },
                    {
                        headers: {
                            'Accept': 'application/vnd.api+json',
                            'Content-Type': 'application/vnd.api+json',
                            'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                        },
                    }
                ).then((response) => {
                    dispatch(setAccountBalance(response.data.data.new_account_balance));
                    setSecondBetRoundId(() => 0);
                    setSecondBet(() => 0);
                    setSecondBetStatus(() => false);
                    dispatch(setMyBets(response.data.data.myBets));
                    dispatch(addMyBets(response?.data.data.bet));
                    infoNotify('Bet of ' + formatAmount(response.data.data.bet.amount) + ' has been cancelled');
                    setSecondBetProcessing(() => false);
                }).catch((error) => {
                    if (!error?.response) {
                        errorNotify('No Server Response');
                    } else {
                        errorNotify(error.message);
                    }
                    setSecondBetProcessing(() => false);

                    if (secondBetRoundId < roundId) {
                        setSecondBetRoundId(() => 0);
                        setSecondBet(() => 0);
                        setSecondBetStatus(() => false);
                    }

                });
            }
        }

    };


    const handleSecondBetButtonCashout = () => {
        if (secondBetProcessing === false) {
            if (secondBet > maximumWinAmount) {
                setSecondBet(() => maximumWinAmount);
            }

            if (authentication === false) {
                successNotify('Congratulations! You have won ' + formatAmount(secondBet));
                if (music === true) {
                    playWinSound();
                }
                var newAccountBalance = parseFloat(accountBalance) + parseFloat(secondBet);
                dispatch(setAccountBalance(newAccountBalance));
                //    setSecondBetAmount(() => defaultBetValue);
                setSecondBetRoundId(() => 0);
                setSecondBet(() => 0);
                setSecondBetMaximumCashoutNotification(() => false);
                setSecondBetStatus(() => false);
            } else {
                setSecondBetProcessing(() => true);
                axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                axios.post(`${baseUrl}/api/cashoutBet`,
                    { autoCashout: false, userId: userId, type: 'second_bet', coefficient_id: roundId, cashout_hash: cashoutHash, cashout: coefficient },
                    {
                        headers: {
                            'Accept': 'application/vnd.api+json',
                            'Content-Type': 'application/vnd.api+json',
                            'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                        },
                    }
                ).then((response) => {
                    dispatch(setAccountBalance(response.data.data.new_account_balance));
                    dispatch(setMyBets(response.data.data.myBets));
                    dispatch(addMyBets(response?.data.data.bet));
                    setSecondBetRoundId(() => 0);
                    setSecondBet(() => 0);
                    setSecondBetMaximumCashoutNotification(() => false);
                    setSecondBetStatus(() => false);
                    successNotify('Congratulations! You have won ' + formatAmount(response.data.data.bet.win_amount));
                    if (music === true) {
                        playWinSound();
                    }
                    setSecondBetProcessing(() => false);
                }).catch((error) => {
                    if (!error?.response) {
                        errorNotify('No Server Response');
                    } else {
                        //   errorNotify(error.message);
                        errorNotify('Cashout Failed! ' + error.response.data.message);
                        //    setSecondBetAmount(() => defaultBetValue);
                        setSecondBetRoundId(() => 0);
                        setSecondBet(() => 0);
                        setSecondBetMaximumCashoutNotification(() => false);
                        setSecondBetStatus(() => false);
                        setSecondBetProcessing(() => false);


                    }
                    setSecondBetProcessing(() => false);
                    if ((secondBetProcessing === false) && (crashPoint !== null) && (roundId === secondBetRoundId) && (secondBet > 0)) {
                        //   setSecondBetAmount(() => defaultBetValue);
                        setSecondBetRoundId(() => 0);
                        warnNotify('Bet of ' + formatAmount(secondBetAmount) + ' lost!');
                        setSecondBet(() => 0);
                        setSecondBetStatus(() => false);
                        setSecondBetMaximumCashoutNotification(() => false);
                        if (music === true) {
                            playExplosion();
                        }

                    }

                });
            }
        }
    }

    const handleSecondBetAmountChange = (event) => {
        setSecondBetAmount(() => event.target.value);
    };
    const verifySecondBetAmountChange = (event) => {
        var newBet = event.target.value;
        setSecondBetAmount(() => newBet);
        formatSecondBetAmount(newBet);
    };


    const secondBetSetAddValue = (value) => {
        var newBet = parseFloat(value.currentTarget.getAttribute("data-value"));
        setSecondBetAmount(() => newBet);
        formatSecondBetAmount(newBet);
    };


    const handleSecondBetMultiplierChange = (event) => {
        setSecondBetMultiplier(() => event.target.value);
    };

    const verifySecondBetMultiplierChange = (event) => {
        var newMultiplier = event.target.value;
        setSecondBetMultiplier(() => newMultiplier);
        formatSecondBetAutoCashout(newMultiplier);
    };

    const secondBetReset = () => {
        setSecondBetAmount(() => defaultBetValue);
        setSecondBetMultiplier(() => 2);
        setSecondBetAutoCashout(() => false);
    };

    const secondBetDivideBet = () => {
        var newBet = secondBetAmount / 2;
        setSecondBetAmount(() => newBet);
        formatSecondBetAmount(newBet);
    };

    const secondBetMultiplyBet = () => {
        var newBet = secondBetAmount * 2;
        setSecondBetAmount(() => newBet);
        formatSecondBetAmount(newBet);
    };

    const secondBetSubtractBet = () => {
        var newBet = secondBetAmount - betInputInterval;
        setSecondBetAmount(() => newBet);
        formatSecondBetAmount(newBet);
    };

    const secondBetAddBet = () => {
        var newBet = parseFloat(secondBetAmount) + parseFloat(betInputInterval);
        setSecondBetAmount(() => newBet);
        formatSecondBetAmount(newBet);
    };
    const secondBetSetMinBet = () => {
        var newBet = minimumBet;
        setSecondBetAmount(() => newBet);
        formatSecondBetAmount(newBet);
    };
    const secondBetSetMaxBet = () => {
        var newBet = maximumBet;
        setSecondBetAmount(() => newBet);
        formatSecondBetAmount(newBet);
    };
    const secondBetAllInBet = () => {
        var newBet = accountBalance;
        setSecondBetAmount(() => newBet);
        formatSecondBetAmount(newBet);
    };


    const secondBetSubtractMultiplier = () => {
        var newMultiplier = secondBetMultiplier - multiplierInputInterval;
        setSecondBetMultiplier(() => newMultiplier);
        formatSecondBetAutoCashout(newMultiplier);
    };

    const secondBetAddMultiplier = () => {
        var newMultiplier = parseFloat(secondBetMultiplier) + parseFloat(multiplierInputInterval);
        setSecondBetMultiplier(() => newMultiplier);
        formatSecondBetAutoCashout(newMultiplier);
    };




    const handleAutoplayAmountChange = (event) => {
        setAutoplayAmount(() => event.target.value);
    };

    const verifyAutoplayAmountChange = (event) => {
        var newBet = event.target.value;
        setAutoplayAmount(() => newBet);
        formatAutoplayAmount(newBet);
    };
    const autoplaySubtractBet = () => {
        var newBet = autoplayAmount - betInputInterval;
        setAutoplayAmount(() => newBet);
        formatAutoplayAmount(newBet);
    };

    const autoplayAddBet = () => {
        var newBet = parseFloat(autoplayAmount) + parseFloat(betInputInterval);
        setAutoplayAmount(() => newBet);
        formatAutoplayAmount(newBet);
    };

    const handleAutoplayMultiplierChange = (event) => {
        setAutoplayMultiplier(() => event.target.value);
    };

    const verifyAutoplayMultiplierChange = (event) => {
        var newMultiplier = event.target.value;
        setAutoplayMultiplier(() => newMultiplier);
        formatAutoplayAutoCashout(newMultiplier);
    };

    const autoplaySubtractMultiplier = () => {
        var newMultiplier = autoplayMultiplier - multiplierInputInterval;
        setAutoplayMultiplier(() => newMultiplier);
        formatAutoplayAutoCashout(newMultiplier);
    };

    const autoplayAddMultiplier = () => {
        var newMultiplier = parseFloat(autoplayMultiplier) + parseFloat(multiplierInputInterval);
        setAutoplayMultiplier(() => newMultiplier);
        formatAutoplayAutoCashout(newMultiplier);
    };

    const handleAutoplayOnWin = () => {
        if (autoplayStatus === false) {
            if (autoplayOnWinIncrease === false) {
                setAutoplayOnWinIncrease(() => true);
            } else {
                setAutoplayOnWinIncrease(() => false);
            }
        }
    };

    const handleAutoplayOnLoss = () => {
        if (autoplayStatus === false) {
            if (autoplayOnLossIncrease === false) {
                setAutoplayOnLossIncrease(() => true);
            } else {
                setAutoplayOnLossIncrease(() => false);
            }
        }
    };




    const handleAutoplayOnWinMultiplierChange = (event) => {
        var newEvent = event.target.value.substring(1);
        setAutoplayOnWinMultiplier(() => newEvent);
    };

    const verifyAutoplayOnWinMultiplierChange = (event) => {
        var newMultiplier = event.target.value.substring(1);
        setAutoplayOnWinMultiplier(() => newMultiplier);
        formatAutoplayOnWinMultiplier(newMultiplier);
    };

    const autoplayOnWinSubtractMultiplier = () => {
        var newMultiplier = autoplayOnWinMultiplier - multiplierInputInterval;
        setAutoplayOnWinMultiplier(() => newMultiplier);
        formatAutoplayOnWinMultiplier(newMultiplier);
    };

    const autoplayOnWinAddMultiplier = () => {
        var newMultiplier = parseFloat(autoplayOnWinMultiplier) + parseFloat(multiplierInputInterval);
        setAutoplayOnWinMultiplier(() => newMultiplier);
        formatAutoplayOnWinMultiplier(newMultiplier);
    };


    const handleAutoplayOnLossMultiplierChange = (event) => {
        setAutoplayOnLossMultiplier(() => event.target.value.substring(1));
    };

    const verifyAutoplayOnLossMultiplierChange = (event) => {
        var newMultiplier = event.target.value.substring(1);
        setAutoplayOnLossMultiplier(() => newMultiplier);
        formatAutoplayOnLossMultiplier(newMultiplier);
    };

    const autoplayOnLossSubtractMultiplier = () => {
        var newMultiplier = autoplayOnLossMultiplier - multiplierInputInterval;
        setAutoplayOnLossMultiplier(() => newMultiplier);
        formatAutoplayOnLossMultiplier(newMultiplier);
    };

    const autoplayOnLossAddMultiplier = () => {
        var newMultiplier = parseFloat(autoplayOnLossMultiplier) + parseFloat(multiplierInputInterval);
        setAutoplayOnLossMultiplier(() => newMultiplier);
        formatAutoplayOnLossMultiplier(newMultiplier);
    };

    //number of rounds
    const handleAutoplayNumberOfRoundsSwitch = () => {
        if (autoplayStatus === false) {
            if (autoplayNumberOfRoundsSwitch === false) {
                setAutoplayNumberOfRoundsSwitch(() => true);
            } else {
                setAutoplayNumberOfRoundsSwitch(() => false);
            }
        }
    };

    const handleAutoplayNumberOfRoundsChange = (event) => {
        var newEvent = event.target.value;
        setAutoplayNumberOfRoundsValue(() => newEvent);
    };

    const verifyAutoplayNumberOfRoundsChange = (event) => {
        var newValue = event.target.value;
        setAutoplayNumberOfRoundsValue(() => newValue);
        formatAutoplayNumberOfRounds(newValue);
    };

    const autoplaySubtractNumberOfRounds = () => {
        var newValue = autoplayNumberOfRoundsValue - numberOfRoundsInputInterval;
        setAutoplayNumberOfRoundsValue(() => newValue);
        formatAutoplayNumberOfRounds(newValue);
    };

    const autoplayAddNumberOfRounds = () => {
        var newValue = parseFloat(autoplayNumberOfRoundsValue) + parseFloat(numberOfRoundsInputInterval);
        setAutoplayNumberOfRoundsValue(() => newValue);
        formatAutoplayNumberOfRounds(newValue);
    };

    //stop if loss
    const handleAutoplayStopIfLossSwitch = () => {
        if (autoplayStatus === false) {
            if (autoplayStopIfLossSwitch === false) {
                setAutoplayStopIfLossSwitch(() => true);
            } else {
                setAutoplayStopIfLossSwitch(() => false);
            }
        }
    };

    const handleAutoplayStopIfLossChange = (event) => {
        var newEvent = event.target.value;
        setAutoplayStopIfLossValue(() => newEvent);
    };

    const verifyAutoplayStopIfLossChange = (event) => {
        var newValue = event.target.value;
        setAutoplayStopIfLossValue(() => newValue);
        formatAutoplayStopIfLoss(newValue);
    };

    const autoplaySubtractStopIfLoss = () => {
        var newValue = autoplayStopIfLossValue - stopIfLossInputInterval;
        setAutoplayStopIfLossValue(() => newValue);
        formatAutoplayStopIfLoss(newValue);
    };

    const autoplayAddStopIfLoss = () => {
        var newValue = parseFloat(autoplayStopIfLossValue) + parseFloat(stopIfLossInputInterval);
        setAutoplayStopIfLossValue(() => newValue);
        formatAutoplayStopIfLoss(newValue);
    };

    //maximum stake amount


    const handleMaximumStakeAmountChange = (event) => {
        var newEvent = event.target.value;
        setMaximumStakeAmount(() => newEvent);
    };

    const verifyMaximumStakeAmountChange = (event) => {
        var newValue = event.target.value;
        setMaximumStakeAmount(() => newValue);
        formatMaximumStakeAmount(newValue);
    };

    const autoplaySubtractMaximumStakeAmount = () => {
        var newValue = maximumStakeAmount - betInputInterval;
        setMaximumStakeAmount(() => newValue);
        formatMaximumStakeAmount(newValue);
    };

    const autoplayAddMaximumStakeAmount = () => {
        var newValue = parseFloat(maximumStakeAmount) + parseFloat(betInputInterval);
        setMaximumStakeAmount(() => newValue);
        formatMaximumStakeAmount(newValue);
    };




    //place bet and reset
    const autoplayReset = () => {
        setAutoplayAmount(() => defaultBetValue);
        setAutoplayMultiplier(() => 2);
        setAutoplayOnWinIncrease(() => false);
        setAutoplayOnLossIncrease(() => false);
        setAutoplayNumberOfRoundsSwitch(() => false);
        setAutoplayStopIfLossSwitch(() => false);
        setAutoplayOnWinMultiplier(() => 2);
        setAutoplayOnLossMultiplier(() => 2);
        setAutoplayNumberOfRoundsValue(() => 10);
        setAutoplayStopIfLossValue(() => 500000);
        setMaximumStakeAmount(() => 500000);

    };















    // //Set autoplay cashout amount 
    useEffect(() => {
        if ((autoplayStatus === true) && (roundId === autoplayRoundId) && (autoplay > 0)) {
            var newAutoplayAmountValue = autoplayAmount * coefficient;
            if (newAutoplayAmountValue < maximumWinAmount) {
                setAutoplay(() => newAutoplayAmountValue);
            } else {
                setAutoplay(() => maximumWinAmount);
            }
        }
    }, [autoplay, autoplayAmount, autoplayRoundId, autoplayStatus, coefficient, maximumWinAmount, roundId]);

    //Set stop if loss to on based on theme 
    useEffect(() => {
        setMaximumStakeAmountStatus(() => true);
    }, [setMaximumStakeAmountStatus]);





    // place next bet
    useEffect(() => {
        if ((autoplayBetPlaced === false) && (autoplayStatus === true) && (roundId === autoplayRoundId) && (autoplayRoundsCounter > 0) && (topDelay >= 5) && (autoplay === 0)) {

            if (autoplayAmount < accountBalance) {
                var newRoundId = parseFloat(roundId) + 1;

                if (authentication === false) {
                    setAutoplayBetPlaced(() => true);
                    setAutoplayRoundId(() => newRoundId);
                    var newAccountBalance = accountBalance - autoplayAmount;
                    //  dispatch(setAccountBalance(newAccountBalance));
                    dispatch(setAccountBalance(newAccountBalance));
                    setMaximumStakeAmountUsed((value) => (parseFloat(value) + parseFloat(autoplayAmount)));
                    setAutoplay(() => autoplayAmount);
                    if (autoplayNumberOfRoundsSwitch === true) {
                        var newRoundsCounter = autoplayRoundsCounter - 1;
                        setAutoplayRoundsCounter(() => newRoundsCounter);
                    }
                    infoNotify('Autoplay Bet of ' + formatAmount(autoplayAmount) + ' placed successfully');
                } else {
                    setAutoplayBetPlaced(() => true);
                    setAutoplayProcessing(() => true);
                    setMaximumStakeAmountUsed((value) => (parseFloat(value) + parseFloat(autoplayAmount)));
                    axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                    axios.post(`${baseUrl}/api/placeBet`,
                        { userId: userId, amount: autoplayAmount, type: 'autoplay', coefficient_id: newRoundId },
                        {
                            headers: {
                                'Accept': 'application/vnd.api+json',
                                'Content-Type': 'application/vnd.api+json',
                                'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                            },
                        }
                    ).then((response) => {
                        dispatch(setAccountBalance(response.data.data.new_account_balance));
                        setAutoplay(() => response.data.data.bet.amount);
                        dispatch(setMyBets(response.data.data.myBets));
                        dispatch(addMyBets(response?.data.data.bet));
                        if (autoplayNumberOfRoundsSwitch === true) {
                            var newRoundsCounter = autoplayRoundsCounter - 1;
                            setAutoplayRoundsCounter(() => newRoundsCounter);
                        }
                        setAutoplayRoundId(() => newRoundId);
                        infoNotify('Autoplay Bet of ' + formatAmount(response.data.data.bet.amount) + ' placed successfully' + response.data.data.message);
                        setAutoplayProcessing(() => false);
                        setAutoplayBetPlaced(() => false);

                    }).catch((error) => {
                        if (!error?.response) {
                            errorNotify('No Server Response');
                        } else {
                            errorNotify(error.message);
                        }
                        setAutoplayProcessing(() => false);
                        setAutoplayBetPlaced(() => false);

                    });


                }

            } else {
                errorNotify('Insufficient funds! Account Balance: ' + formatAmount(accountBalance));
                setAutoplayRoundId(() => 0);
                setAutoplay(() => 0);
                setAutoplayStatus(() => false);
                setAutoplayRoundsCounter(() => 0);
                warnNotify('Autoplay Bet of has been cancelled');
            }

        }
    }, [authentication, autoplay, autoplayAmount, autoplayBetPlaced, autoplayNumberOfRoundsSwitch, autoplayRoundId, autoplayRoundsCounter, autoplayStatus, baseUrl, dispatch, roundId, setMyBets, topDelay, userId]);


    //process win
    useEffect(() => {

        if ((autoplayStatus === true) && (autoplay > 0) && (roundId === autoplayRoundId) && (coefficient >= autoplayMultiplier)) {
            setAutoplay(() => 0);
            setAutoplayBetPlaced(() => false);

            var newAutoplayValue = autoplayMultiplier * autoplayAmount;
            if (newAutoplayValue > maximumWinAmount) {
                newAutoplayValue = maximumWinAmount;
            }


            if (authentication === false) {
                successNotify('Congratulations! You have won ' + formatAmount(newAutoplayValue));
                if (music === true) {
                    playWinSound();
                }
                var newAccountBalance = parseFloat(accountBalance) + parseFloat(newAutoplayValue);
                dispatch(setAccountBalance(newAccountBalance));
            } else {
                //  setFirstBetAutoCashout(() => false);
                setAutoplayProcessing(() => true);
                axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                axios.post(`${baseUrl}/api/cashoutBet`,
                    { autoCashout: true, userId: userId, type: 'autoplay', coefficient_id: roundId, cashout_hash: cashoutHash, cashout: coefficient },
                    {
                        headers: {
                            'Accept': 'application/vnd.api+json',
                            'Content-Type': 'application/vnd.api+json',
                            'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                        },
                    }
                ).then((response) => {
                    successNotify('Congratulations! You have won ' + formatAmount(response.data.data.bet.win_amount));
                    if (music === true) {
                        playWinSound();
                    }
                    dispatch(setAccountBalance(response.data.data.new_account_balance));
                    dispatch(setMyBets(response.data.data.myBets));
                    dispatch(addMyBets(response?.data.data.bet));
                    setAutoplayProcessing(() => false);
                }).catch((error) => {
                    if (!error?.response) {
                        errorNotify('No Server Response');
                    } else {
                        // errorNotify(error.message);
                        errorNotify('Cashout Failed! ' + error.response.data.message);
                        setAutoplayProcessing(() => false);
                    }
                    //    setFirstBetAutoCashout(() => true);
                    setAutoplayProcessing(() => false);
                });
            }


            if (autoplayOnWinIncrease === true) {
                var newAutoplayAmount = autoplayAmount * autoplayOnWinMultiplier;
                setAutoplayAmount(() => newAutoplayAmount);
            }
            if ((autoplayNumberOfRoundsSwitch === true) && (autoplayRoundsCounter === 0)) {
                setAutoplayRoundId(() => 0);
                setAutoplay(() => 0);
                setAutoplayStatus(() => false);
                setAutoplayRoundsCounter(() => 0);
                warnNotify('Autoplay Bet of has been cancelled');
            }


            //cancel autobet if maximum stake amount exhausted
            if ((maximumStakeAmountStatus === true) && (maximumStakeAmountUsed >= maximumStakeAmount)) {
                setAutoplayRoundId(() => 0);
                setAutoplay(() => 0);
                setAutoplayStatus(() => false);
                setAutoplayRoundsCounter(() => 0);
                setMaximumStakeAmountUsed(() => 0);
                warnNotify('Autoplay Bet of has been cancelled. Maximum Stake Amount Exhausted');
            }

            //update autoplay amount if greater maximum stake amount used
            if ((maximumStakeAmountStatus === true) && (autoplayAmount > (maximumStakeAmount - maximumStakeAmountUsed))) {
                var diffValue = maximumStakeAmount - maximumStakeAmountUsed;
                setAutoplayAmount(() => diffValue);
            }


        }
    }, [authentication, autoplay, autoplayAmount, autoplayMultiplier, autoplayNumberOfRoundsSwitch, autoplayOnWinIncrease, autoplayOnWinMultiplier, autoplayRoundId, autoplayRoundsCounter, autoplayStatus, baseUrl, cashoutHash, coefficient, dispatch, maximumStakeAmount, maximumStakeAmountStatus, maximumStakeAmountUsed, maximumWinAmount, roundId, setMyBets, userId]);


    //process loss, remember to keep track of loss amount so you can process the on loss value
    useEffect(() => {
        if ((autoplayProcessing === false) && (autoplayStatus === true) && (coefficient === null) && (autoplay > 0) && (roundId === autoplayRoundId)) {
            setAutoplay(() => 0);
            setAutoplayBetPlaced(() => false);

            var newAutoplayValue = autoplayAmount;
            warnNotify('You have lost ' + formatAmount(newAutoplayValue));
            if (music === true) {
                playExplosion();
            }

            var newLossValue = parseFloat(autoplayLossValue) + parseFloat(newAutoplayValue);
            setAutoplayLossValue(() => newLossValue);
            if (autoplayOnLossIncrease === true) {
                var newAutoplayAmountLoss = autoplayAmount * autoplayOnLossMultiplier;
                setAutoplayAmount(() => newAutoplayAmountLoss);
            }
            if ((autoplayStopIfLossSwitch === true) && (autoplayLossValue >= autoplayStopIfLossValue)) {
                setAutoplayRoundId(() => 0);
                setAutoplay(() => 0);
                setAutoplayStatus(() => false);
                setAutoplayRoundsCounter(() => 0);
                warnNotify('Autoplay Bet of has been cancelled');
            }
            if ((autoplayNumberOfRoundsSwitch === true) && (autoplayRoundsCounter === 0)) {
                setAutoplayRoundId(() => 0);
                setAutoplay(() => 0);
                setAutoplayStatus(() => false);
                setAutoplayRoundsCounter(() => 0);
                warnNotify('Autoplay Bet of has been cancelled');
            }
            //cancel autobet if maximum stake amount exhausted
            if ((maximumStakeAmountStatus === true) && (maximumStakeAmountUsed >= maximumStakeAmount)) {
                setAutoplayRoundId(() => 0);
                setAutoplay(() => 0);
                setAutoplayStatus(() => false);
                setAutoplayRoundsCounter(() => 0);
                setMaximumStakeAmountUsed(() => 0);
                warnNotify('Autoplay Bet of has been cancelled. Maximum Stake Amount Exhausted');
            }
            //update autoplay amount if greater maximum stake amount used
            if ((maximumStakeAmountStatus === true) && (autoplayAmount > (maximumStakeAmount - maximumStakeAmountUsed))) {
                var diffValue = maximumStakeAmount - maximumStakeAmountUsed;
                setAutoplayAmount(() => diffValue);
            }

        }
    }, [autoplay, autoplayAmount, autoplayLossValue, autoplayNumberOfRoundsSwitch, autoplayOnLossIncrease, autoplayOnLossMultiplier, autoplayProcessing, autoplayRoundId, autoplayRoundsCounter, autoplayStatus, autoplayStopIfLossSwitch, autoplayStopIfLossValue, coefficient, maximumStakeAmountUsed, roundId]);




    //cashout if autoplay Amount exceeds the maximum win allowed
    useEffect(() => {
        if ((autoplay === maximumWinAmount) && (autoplayStatus === true) && (roundId === autoplayRoundId)) {
            setAutoplay(() => 0);
            setAutoplayBetPlaced(() => false);

            warnNotify('Maximum win amount of ' + formatAmount(maximumWinAmount) + ' reached!');
            var newAutoplayValue = autoplayMultiplier * autoplayAmount;
            if (newAutoplayValue > maximumWinAmount) {
                newAutoplayValue = maximumWinAmount;
            }


            if (authentication === false) {
                successNotify('Congratulations! You have won ' + formatAmount(newAutoplayValue));
                if (music === true) {
                    playWinSound();
                }
                var newAccountBalance = parseFloat(accountBalance) + parseFloat(newAutoplayValue);
                dispatch(setAccountBalance(newAccountBalance));
            } else {
                //  setFirstBetAutoCashout(() => false);
                setAutoplayProcessing(() => true);
                axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                axios.post(`${baseUrl}/api/cashoutBet`,
                    { autoCashout: true, userId: userId, type: 'autoplay', coefficient_id: roundId, cashout_hash: cashoutHash, cashout: coefficient },
                    {
                        headers: {
                            'Accept': 'application/vnd.api+json',
                            'Content-Type': 'application/vnd.api+json',
                            'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                        },
                    }
                ).then((response) => {
                    successNotify('Congratulations! You have won ' + formatAmount(response.data.data.bet.win_amount));
                    if (music === true) {
                        playWinSound();
                    }
                    dispatch(setAccountBalance(response.data.data.new_account_balance));
                    dispatch(setMyBets(response.data.data.myBets));
                    dispatch(addMyBets(response?.data.data.bet));
                    setAutoplayProcessing(() => false);
                }).catch((error) => {
                    if (!error?.response) {
                        errorNotify('No Server Response');
                    } else {
                        //   errorNotify(error.message);
                        errorNotify('Cashout Failed! ' + error.response.data.message);
                        setAutoplayProcessing(() => false);
                    }
                    //    setFirstBetAutoCashout(() => true);
                    setAutoplayProcessing(() => false);
                });
            }



            if (autoplayOnWinIncrease === true) {
                var newAutoplayAmount = autoplayAmount * autoplayOnWinMultiplier;
                setAutoplayAmount(() => newAutoplayAmount);
            }
            if ((autoplayNumberOfRoundsSwitch === true) && (autoplayRoundsCounter === 0)) {
                setAutoplayRoundId(() => 0);
                setAutoplay(() => 0);
                setAutoplayStatus(() => false);
                setAutoplayRoundsCounter(() => 0);
                warnNotify('Autoplay Bet of has been cancelled');
            }
            //cancel autobet if maximum stake amount exhausted
            if ((maximumStakeAmountStatus === true) && (maximumStakeAmountUsed >= maximumStakeAmount)) {
                setAutoplayRoundId(() => 0);
                setAutoplay(() => 0);
                setAutoplayStatus(() => false);
                setAutoplayRoundsCounter(() => 0);
                setMaximumStakeAmountUsed(() => 0);
                warnNotify('Autoplay Bet of has been cancelled. Maximum Stake Amount Exhausted');
            }

            //update autoplay amount if greater maximum stake amount used
            if ((maximumStakeAmountStatus === true) && (autoplayAmount > (maximumStakeAmount - maximumStakeAmountUsed))) {
                var diffValue = maximumStakeAmount - maximumStakeAmountUsed;
                setAutoplayAmount(() => diffValue);
            }


        }
    }, [authentication, autoplay, autoplayAmount, autoplayMultiplier, autoplayNumberOfRoundsSwitch, autoplayOnWinIncrease, autoplayOnWinMultiplier, autoplayRoundId, autoplayRoundsCounter, autoplayStatus, baseUrl, cashoutHash, coefficient, dispatch, maximumStakeAmount, maximumStakeAmountStatus, maximumStakeAmountUsed, maximumWinAmount, roundId, setMyBets, userId]);



    const handleAutoplayBetButton = () => {
        if (accountBalance >= autoplayAmount) {
            if (autoplayAmount >= minimumBet) {
                setAutoplayStatus(() => true);
                setAutoplayRoundsCounter(() => autoplayNumberOfRoundsValue);
                setAutoplayRoundId(() => roundId);
            } else {
                errorNotify('Insufficient funds! Minimum Bet allowed is: ' + formatAmount(minimumBet));
            }
        } else {
            errorNotify('Insufficient funds! Account Balance: ' + formatAmount(accountBalance));
        }

    };

    const handleAutoplayCancelButton = () => {

        if (authentication === false) {
            var newAccountBalance = parseFloat(accountBalance) + parseFloat(autoplayAmount);
            dispatch(setAccountBalance(newAccountBalance));
            setAutoplayRoundId(() => 0);
            warnNotify('Autoplay Bet of ' + formatAmount(autoplayAmount) + ' has been cancelled');
            setAutoplay(() => 0);
            setAutoplayStatus(() => false);
            setAutoplayRoundsCounter(() => 0);
        } else {
            setAutoplayProcessing(() => true);
            axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            axios.post(`${baseUrl}/api/cancelBet`,
                { userId: userId, type: 'autoplay', coefficient_id: roundId },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            ).then((response) => {
                dispatch(setAccountBalance(response.data.data.new_account_balance));
                dispatch(setMyBets(response.data.data.myBets));
                dispatch(addMyBets(response?.data.data.bet));
                setAutoplayRoundId(() => 0);
                infoNotify('Autoplay Bet of ' + formatAmount(response.data.data.bet.amount) + ' has been cancelled');
                setAutoplay(() => 0);
                setAutoplayStatus(() => false);
                setAutoplayRoundsCounter(() => 0);
                setAutoplayProcessing(() => false);
            }).catch((error) => {
                if (!error?.response) {
                    errorNotify('No Server Response');
                } else {
                    setAutoplayRoundId(() => 0);
                    warnNotify('Autoplay has been cancelled');
                    setAutoplay(() => 0);
                    setAutoplayStatus(() => false);
                    setAutoplayRoundsCounter(() => 0);
                }
                setAutoplayProcessing(() => false);

            });
        }

    };




    return (
        <>




            <>
                <div className="flex justify-center items-center bg-[#181c3a] m-0.5 rounded-xl p-0.5">
                    <div className="w-full relative z-0 ">

                        <div className="  bg-cover bg-opacity-50 z-40" style={{ display: "grid", placeItems: "center" }}>
                            <ResponsiveContainer className="text-xs text-gray-300 z-30" width="100%" height={250}>
                                <AreaChart data={data}
                                    margin={{ top: 20, right: 30, left: 30, bottom: 30 }}>
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#c35520" stopOpacity={0.9} />
                                            <stop offset="95%" stopColor="#c35520" stopOpacity={0.3} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis
                                        stroke="#aaa"
                                        dataKey={'xAxis'}
                                        name='stature'
                                        domain={[1, 2]}
                                        minTickGap={40}
                                        type="number"
                                        unit='x'
                                        hide="true"



                                    />
                                    <YAxis
                                        stroke="#aaa"
                                        domain={[1, 6]}
                                        hide="true"
                                    />
                                    <Area isAnimationActive={false} animationDuration={1000} animationEasing="ease" type="monotone" dataKey="pv" stroke="#ffb805" strokeWidth={2} fillOpacity={1} fill="url(#colorPv)" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>


                        <>
                            <div className="absolute inset-0 z-30 opacity-50"
                                style={{ display: "grid", placeItems: "center" }}>
                                <ResponsiveContainer className="text-xs text-gray-300 z-30" width="100%" height={250}>
                                    <AreaChart
                                        margin={{ top: 32, right: 30, left: -20, bottom: 0 }}>
                                        <CartesianGrid stroke="#777" strokeDasharray="3 3" verticalCoordinatesGenerator={(props) => props.width > 150 ? [] : [200, 400, 600, 800, 1000]} />

                                        <XAxis
                                            stroke="#aaa"
                                            //  dataKey={'xAxis'}
                                            name='stature'
                                            //  ticks={[1,2,3,4,5,6]}
                                            domain={[1, 1.2, 1.4, 1.6, 1.8, 2]}
                                            minTickGap={40}
                                            // type="number"
                                            unit='x'

                                        />
                                        <YAxis
                                            //  unit="cm"
                                            stroke="#aaa"
                                            domain={[1, 2, 4, 6, 8, 10]}
                                        // dataKey={'xAxis'}

                                        // hide="true"
                                        />

                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </>




                        <div

                            className={"bottom z-50 absolute inset-x-0 flex "}
                            style={{
                                left: `${airplaneLeft}px`,
                                bottom: `${airplaneTop}px`,
                            }}
                        >
                            {/* <img className="h-auto max-h-10 md:max-h-[60px] rotate-[320deg]  max-w-full md:rotate-[340deg]" src={AirplaneStill} alt="airplane"></img> */}

                            <div className=' h-auto max-h-10 md:max-h-[60px] rotate-[320deg]  max-w-full md:rotate-[340deg]'>
                                {crashPoint === null &&
                                    <Airplane />
                                }

                                {crashPoint !== null &&
                                    <AirplaneCrash />
                                }

                            </div>
                        </div>




                        <div ref={airplaneField} className='absolute inset-0 z-50 opacity-50 w-auto  mx-10 my-6'>
                            <p>

                            </p>
                        </div>


                        <div id="aviatorField" className='absolute inset-0 z-30 opacity-50 w-auto text-gray-50 text-lg ml-[4%] mr-[20%] mb-[3.2%] mt-[7%]'>
                            <p>

                            </p>
                        </div>





                        <div className="z-0 w-full">

                            <div className="absolute inset-0 w-full" >
                                <Marquee className="-bottom-[68px] w-full grid grid-cols-3" speed={10} play={coefficient !== null ? true : false}>
                                    <img className="bottom object-bottom inset-x-0 flex object-fill w-full " src={Mountain5} alt="mountain 5"></img>
                                    <img className="bottom object-bottom inset-x-0 flex object-fill w-full " src={Mountain5} alt="mountain 5"></img>
                                    <img className="bottom object-bottom inset-x-0 flex object-fill w-full " src={Mountain5} alt="mountain 5"></img>
                                </Marquee>
                            </div>

                            <div className="absolute inset-0 w-full">
                                <Marquee className="-bottom-[88px] w-full grid grid-cols-3" speed={50} play={coefficient !== null ? true : false}>
                                    <img className="bottom object-none object-bottom inset-x-0 flex w-full " src={Mountain4} alt="mountain 4"></img>
                                    <img className="bottom object-none object-bottom inset-x-0 flex w-full " src={Mountain4} alt="mountain 4"></img>
                                    <img className="bottom object-none object-bottom inset-x-0 flex w-full " src={Mountain4} alt="mountain 4"></img>
                                </Marquee>
                            </div>
                            <div className="absolute inset-0 w-full">
                                <Marquee className="-bottom-[108px] w-full grid grid-cols-3" speed={100} play={coefficient !== null ? true : false}>
                                    <img className="bottom object-none object-bottom inset-x-0 flex w-full " src={Mountain3} alt="mountain 3"></img>
                                    <img className="bottom object-none object-bottom inset-x-0 flex w-full " src={Mountain3} alt="mountain 3"></img>
                                    <img className="bottom object-none object-bottom inset-x-0 flex w-full " src={Mountain3} alt="mountain 3"></img>
                                </Marquee>
                            </div>
                            <div className="absolute inset-0 w-full">
                                <Marquee className="-bottom-[128px] w-full grid grid-cols-3" speed={150} play={coefficient !== null ? true : false}>
                                    <img className="bottom object-none object-bottom inset-x-0 flex w-full " src={Mountain2} alt="mountain 2"></img>
                                    <img className="bottom object-none object-bottom inset-x-0 flex w-full " src={Mountain2} alt="mountain 2"></img>
                                    <img className="bottom object-none object-bottom inset-x-0 flex w-full " src={Mountain2} alt="mountain 2"></img>
                                </Marquee>
                            </div>
                            <div className="absolute inset-0 w-full">
                                <Marquee className="-bottom-[148px] w-full grid grid-cols-3" speed={200} play={coefficient !== null ? true : false}>
                                    <img className=" bottom object-bottom object-scale-down inset-x-0 flex w-full " src={Mountain1} alt="mountain 1"></img>
                                    <img className=" bottom object-bottom object-scale-down inset-x-0 flex w-full " src={Mountain1} alt="mountain 1"></img>
                                    <img className=" bottom object-bottom object-scale-down inset-x-0 flex w-full " src={Mountain1} alt="mountain 1"></img>
                                </Marquee>
                            </div>

                        </div>



                        <div className="bg-[#181c3a] absolute inset-0 z-10 opacity-70">
                            <p></p>
                        </div>


                        <div className="bottom-8 right-4 z-40 absolute inset-0 flex place-content-end text-gray-50 text-3xl md:text-6xl font-black mx-auto rounded-xl" >
                            <div className=" grid grid-cols-1 gap-4 place-content-end ">
                                <div className="-mb-4 grid grid-cols-1 place-content-end ">
                                    <span className="text-center animate-bounce coefficient-font">{formatCrashPoint(crashPoint)}</span>
                                </div>

                                {/* <div className="coefficient-font">
                                    {rr}
                                </div> */}
                                <div className="coefficient-font">
                                    {formatCoefficient(coefficient)}
                                    {delay}
                                </div>
                            </div>
                        </div>







                        <div className="z-40 absolute inset-x-0 -bottom-1 flex place-content-end text-gray-400 text-xs ">
                            <p>Round&nbsp;ID:&nbsp;{roundId}&nbsp;</p>
                        </div>

                        <div className="z-40 absolute inset-0 flex justify-start text-gray-300 text-xs overflow-clip mx-2 mt-1 ">
                            {previousGames.map(({ id, coefficient }, index) => {
                                return (

                                    <span key={id} >
                                        {coefficient < 2 &&
                                            <button className="flex justify-start top-0 mx-0.5 h-5 px-2 py-0.5 text-gray-100 text-xs rounded-md bg-none border border-[#0652dd] bg-[#0652dd] hover:bg-transparent hover:border-[#0652dd] hover:text-[#0652dd] active:border-0 transition duration-700 ease-in-out font-bold">{formatCoefficient(coefficient)}</button>
                                        }
                                        {(coefficient >= 2) && (coefficient < 10) &&
                                            <button className="flex justify-start top-0 mx-0.5 h-5 px-2 py-0.5 text-[#111111] text-xs rounded-md bg-none border border-[#8fc92c] bg-[#8fc92c] hover:bg-transparent hover:border-[#8fc92c] hover:text-[#8fc92c] active:border-0 transition duration-700 ease-in-out font-bold">{formatCoefficient(coefficient)}</button>
                                        }
                                        {coefficient >= 10 &&
                                            <button className="flex justify-start top-0 mx-0.5 h-5 px-2 py-0.5 text-[#111111] text-xs rounded-md bg-none border border-[#ffbc00] bg-[#ffbc00] hover:bg-transparent hover:border-[#ffbc00] hover:text-[#ffbc00] active:border-0 transition duration-700 ease-in-out font-bold">{formatCoefficient(coefficient)}</button>
                                        }
                                    </span>
                                );
                            })}

                        </div>



                    </div>



                </div>
            </>

            <>
                <div className="bg-[#151937] rounded p-2">
                    {/* <div className="bg-[#1b1c1d] rounded p-2">   */}

                    {/* <div className="text-[#fff]" >
                        <span>{accountBalance} - </span>
                        <span>{firstBet} - </span>
                        <span>{firstBetRoundId}</span>
                        <span></span>
                    </div> */}

                    <Tabs id="custom-animation-control" value="manual">
                        <TabsHeader
                            // className="rounded-sm bg-gradient-to-r from-[#49c92c] to-[#0652dd]  p-0.5 h-7"
                            // className="rounded-sm bg-gradient-to-r from-[#f7a026] to-[#c83c48]  p-0.5 h-7"
                            // className="rounded-sm bg-gradient-to-r from-[#f7a026] to-[#49c92c]  p-0.5 h-7"
                            // className="rounded-sm bg-gradient-to-r from-[#d25c18] to-[#c83c48]  p-0.5 h-7"
                            //  className="rounded-sm bg-gradient-to-r from-[#f7a026] to-[#0652dd]  p-0.5 h-7"
                            className="rounded-sm bg-gradient-to-r from-[#eb5ccf] to-[#642269]  p-0.5 h-7"

                            indicatorProps={{
                                className: "rounded-sm bg-[#151937] shadow-none",
                            }}
                        >
                            <Tab key="manual" value="manual" className="text-gray-200 text-xs uppercase">
                                Stake Selector
                            </Tab>
                            <Tab key="autoplay" value="autoplay" className="text-gray-200 text-xs uppercase">
                                Autoplay
                            </Tab>
                        </TabsHeader>
                        <TabsBody
                            animate={{
                                initial: { y: 250 },
                                mount: { y: 0 },
                                unmount: { y: 250 },
                            }}
                        >
                            <TabPanel className="my-1 p-0" key="manual" value="manual">
                                <div className="gap-y-3 gap-x-4 ">

                                    <div>
                                        <div className="grid grid-cols-1 grid-cols-2 gap-2 ">
                                            <div>
                                                <span className="text-xs text-gray-500 my-0 py-0">First Bet</span>
                                                <div className={"flex items-stretch gap-2 m-0.5" + ((firstBetStatus === false) ? "" : " opacity-50")}>
                                                    <button onClick={firstBetDivideBet} disabled={firstBetStatus} size="sm" className="w-8 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">1/2</button>
                                                    <div className="p-px flex items-stretch w-full bg-[#413f8d] rounded">
                                                        <div className="flex items-stretch w-full bg-gray-50 rounded">
                                                            <button onClick={firstBetSubtractBet} disabled={firstBetStatus} size="sm" className="h-5 w-6 m-0.5 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">-</button>
                                                            <input
                                                                type="number"
                                                                className="pt-1 text-center w-full text-[#151937] text-xs font-medium bg-transparent outline-none"
                                                                id="firstBet"
                                                                name="test"
                                                                disabled={firstBetStatus}
                                                                value={firstBetAmount}
                                                                onChange={handleFirstBetAmountChange}
                                                                onBlur={verifyFirstBetAmountChange}
                                                            />
                                                            <button onClick={firstBetAddBet} disabled={firstBetStatus} size="sm" className="h-5 w-6 m-0.5 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">+</button>
                                                        </div>
                                                    </div>
                                                    <button onClick={firstBetMultiplyBet} disabled={firstBetStatus} size="sm" className="w-8 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">x2</button>
                                                </div>
                                                {/* <div className={"flex items-stretch gap-2 mt-2 m-0.5" + ((firstBetStatus === false) ? "" : " opacity-50")}>
                                        <button onClick={firstBetSetAddValue} data-value={firstPresetInput} disabled={firstBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{firstPresetInput}</button>
                                        <button onClick={firstBetSetAddValue} data-value={secondPresetInput} disabled={firstBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{secondPresetInput}</button>
                                        <button onClick={firstBetSetAddValue} data-value={thirdPresetInput} disabled={firstBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{thirdPresetInput}</button>
                                        <button onClick={firstBetSetAddValue} data-value={fourthPresetInput} disabled={firstBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{fourthPresetInput}</button>
                                    </div>
                                    <div className={"flex items-stretch gap-2 mt-2 m-0.5 text-center" + ((firstBetStatus === false) ? "" : " opacity-50")}>
                                        <button onClick={firstBetSetAddValue} data-value={fifthPresetInput} disabled={firstBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{fifthPresetInput}</button>
                                        <button onClick={firstBetSetAddValue} data-value={sixthPresetInput} disabled={firstBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{sixthPresetInput}</button>
                                        <button onClick={firstBetSetAddValue} data-value={seventhPresetInput} disabled={firstBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{seventhPresetInput}</button>
                                        <button onClick={firstBetReset} disabled={firstBetStatus} size="sm" className=" flex items-center justify-center p-1 w-full text-gray-50 text-xs rounded bg-[#b11b1b] border border-[#b11b1b] hover:bg-[#0f214d] hover:border-[#ca3f43] hover:text-[#ca3f43] active:border-0 transition duration-700 ease-in-out">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.0" stroke="currentColor" className="w-4 h-3 ">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                            </svg>
                                        </button>
                                    </div> */}


                                                <div className={"flex items-stretch gap-2 mt-2 m-0.5 text-center" + ((firstBetStatus === false) ? "" : " opacity-50")}>
                                                    <button onClick={firstBetSetAddValue} data-value={firstPresetInput} disabled={firstBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{firstPresetInput}</button>
                                                    <button onClick={firstBetSetAddValue} data-value={secondPresetInput} disabled={firstBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{secondPresetInput}</button>
                                                    <button onClick={firstBetSetAddValue} data-value={thirdPresetInput} disabled={firstBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{thirdPresetInput}</button>
                                                    <button onClick={firstBetReset} disabled={firstBetStatus} size="sm" className=" flex items-center justify-center p-1 w-full text-gray-50 text-xs rounded bg-[#b11b1b] border border-[#b11b1b] hover:bg-[#0f214d] hover:border-[#ca3f43] hover:text-[#ca3f43] active:border-0 transition duration-700 ease-in-out">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.0" stroke="currentColor" className="w-4 h-3 ">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                                        </svg>
                                                    </button>
                                                </div>


                                                <div className="mt-1.5 grid grid-cols-1">
                                                    <div className={"grid grid-cols-4" + ((firstBetStatus === false) ? "" : " opacity-50")}>
                                                        <div className=" col-span-2 ">
                                                            <div className="mr-2  space-x-2 flex items-stretch max-h-5">
                                                                <span className="leading-5 text-xs text-gray-100 ">
                                                                    Auto
                                                                </span>
                                                                <span className="">
                                                                    <Switch
                                                                        className='h-3 mt-0.5 w-5 bg-[#2e335c]' id="firstBetSwitch" color="orange" ripple={true}
                                                                        containerProps={{
                                                                            className: "",
                                                                        }}
                                                                        circleProps={{
                                                                            className: "h-3 w-3 ",
                                                                        }}
                                                                        name="firstBetAutoCashout"
                                                                        disabled={firstBetStatus}
                                                                        // value={firstBetAutoCashout}
                                                                        onChange={handleFirstBetAutoCashout}
                                                                        checked={firstBetAutoCashout}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-2">
                                                            <div className=" p-px flex items-stretch w-full bg-[#413f8d] rounded">
                                                                <div className={"flex items-stretch w-full  rounded " + (!firstBetAutoCashout ? " opacity-50 bg-[#2e335c]" : " bg-gray-50")}>
                                                                    <button onClick={firstBetSubtractMultiplier} disabled={(!firstBetAutoCashout || firstBetStatus)} size="" className={"leading-[0.50rem] h-4 w-6 m-0.5 rounded text-gray-50 text-xs bg-[#2e335c] border border-[#413f8d]  active:border-0 transition duration-700 ease-in-out" + (!firstBetAutoCashout ? "" : " hover:bg-[#f06514] hover:border-[#f06514]")}>-</button>
                                                                    <input
                                                                        type="number"
                                                                        className={"pt-1 text-center w-full  font-medium text-xs bg-transparent outline-none " + (!firstBetAutoCashout ? "text-gray-500" : "text-[#151937]")}
                                                                        id="firstBetMultiplier"
                                                                        disabled={(!firstBetAutoCashout || firstBetStatus)}
                                                                        value={firstBetMultiplier}
                                                                        onChange={handleFirstBetMultiplierChange}
                                                                        onBlur={verifyFirstBetMultiplierChange}
                                                                    />
                                                                    <button onClick={firstBetAddMultiplier} disabled={(!firstBetAutoCashout || firstBetStatus)} size="" className={"leading-[0.50rem] h-4 w-6 m-0.5 rounded text-gray-50 text-xs bg-[#2e335c] border border-[#413f8d]  active:border-0 transition duration-700 ease-in-out" + (!firstBetAutoCashout ? "" : " hover:bg-[#f06514] hover:border-[#f06514]")}>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="mt-1.5 grid grid-cols-1">
                                                    <div className="ml-2 mt-1.5">
                                                        {firstBetRoundId === 0 &&
                                                            //   <button onClick={handleFirstBetButtonClick} disabled={firstBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded  bg-[#7aa82a] border  border-[#7aa82a] hover:bg-[#49c92c] hover:border-[#49c92c]  active:border-0 transition duration-700 ease-in-out"> 
                                                            //  <button onClick={handleFirstBetButtonClick} disabled={firstBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded  bg-[#0652dd] border  border-[#0652dd] hover:bg-[#49c92c] hover:border-[#49c92c]  active:border-0 transition duration-700 ease-in-out"> 
                                                            //   <button onClick={handleFirstBetButtonClick} disabled={firstBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded  bg-[#f7a026] border  border-[#f7a026] hover:bg-[#49c92c] hover:border-[#49c92c]  active:border-0 transition duration-700 ease-in-out">
                                                            //  <button onClick={handleFirstBetButtonClick} disabled={firstBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded  bg-[#49c92c] border  border-[#49c92c] hover:bg-[#f7a026] hover:border-[#f7a026]  active:border-0 transition duration-700 ease-in-out">
                                                            //   <button onClick={handleFirstBetButtonClick} disabled={firstBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded bg-[#f06514] border border-[#f06514] hover:bg-[#ca3f43] hover:border-[#ca3f43] active:border-0 transition duration-700 ease-in-out">
                                                            //  <button onClick={handleFirstBetButtonClick} disabled={firstBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded  bg-[#f7a026] border  border-[#f7a026] hover:bg-[#49c92c] hover:border-[#49c92c]  active:border-0 transition duration-700 ease-in-out">
                                                            <button onClick={handleFirstBetButtonClick} disabled={firstBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded  bg-[#ac3090] border  border-[#ac3090] hover:bg-[#49c92c] hover:border-[#49c92c]  active:border-0 transition duration-700 ease-in-out">


                                                                {firstBetProcessing === false &&
                                                                    <span>
                                                                        PLACE A BET
                                                                    </span>
                                                                }
                                                                {firstBetProcessing === true &&
                                                                    <span className="flex items-center justify-center gap-2 mx-auto">
                                                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                        </svg>
                                                                        Processing...
                                                                    </span>
                                                                }
                                                            </button>
                                                        }
                                                        {(firstBetRoundId > 0) && (roundId !== firstBetRoundId) && (delay === null) &&
                                                            <button onClick={handleFirstBetButtonCancel} disabled={firstBetProcessing} size="lg" className="p-3 w-full h-full text-gray-300 bg-gradient-to-b from-red-600 via-red-800 to-red-900 shadow-red hover:shadow-hoverRed text-xs font-medium rounded active:bg-red-800 active:shadow">
                                                                {firstBetProcessing === false &&
                                                                    <span>
                                                                        CANCEL
                                                                    </span>
                                                                }
                                                                {firstBetProcessing === true &&
                                                                    <span className="flex items-center justify-center gap-2 mx-auto">
                                                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                        </svg>
                                                                        Processing...
                                                                    </span>
                                                                }
                                                            </button>
                                                        }
                                                        {(roundId === firstBetRoundId) && (firstBetRoundId > 0) &&
                                                            <button onClick={handleFirstBetButtonCashout} disabled={firstBetProcessing} size="lg" className="p-3 w-full h-full font-bold  text-[#111111] text-xs rounded  bg-[#49c92c] border border-[#58c92c] hover:border-[#ca3f43] hover:bg-[#f06514]  active:border-0 transition duration-700 ease-in-out">
                                                                {firstBetProcessing === false &&
                                                                    <span>
                                                                        TAKE WINNINGS {formatAmount(firstBet)}
                                                                    </span>
                                                                }
                                                                {firstBetProcessing === true &&
                                                                    <span className="flex items-center justify-center gap-2 mx-auto">
                                                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                        </svg>
                                                                        Processing...
                                                                    </span>
                                                                }
                                                            </button>
                                                        }
                                                        {(firstBetRoundId > 0) && (roundId !== firstBetRoundId) && (delay !== null) &&
                                                            <button size="lg" className="p-3 w-full h-full text-gray-100 bg-gradient-to-b from-gray-400 via-gray-600 to-gray-700 shadow-gray hover:shadow-hoverGray text-xs font-medium rounded active:bg-gray-800 active:shadow cursor-not-allowed opacity-50" disabled>
                                                                {firstBetProcessing === false &&
                                                                    <span>
                                                                        TAKE WINNINGS
                                                                    </span>
                                                                }
                                                                {firstBetProcessing === true &&
                                                                    <span className="flex items-center justify-center gap-2 mx-auto">
                                                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                        </svg>
                                                                        Processing...
                                                                    </span>
                                                                }
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>


                                            {/* second bet */}
                                            <div>
                                                <span className="text-xs text-gray-500 my-0 py-0">Second Bet</span>
                                                <div className={"flex items-stretch gap-2 m-0.5" + ((secondBetStatus === false) ? "" : " opacity-50")}>
                                                    <button onClick={secondBetDivideBet} disabled={secondBetStatus} size="sm" className="w-8 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">1/2</button>
                                                    <div className="p-px flex items-stretch w-full bg-[#413f8d] rounded">
                                                        <div className="flex items-stretch w-full bg-gray-50 rounded">
                                                            <button onClick={secondBetSubtractBet} disabled={secondBetStatus} size="sm" className="h-5 w-6 m-0.5 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">-</button>
                                                            <input
                                                                type="number"
                                                                className="pt-1 text-center w-full text-[#151937] text-xs font-medium bg-transparent outline-none"
                                                                id="secondBet"
                                                                name="test"
                                                                disabled={secondBetStatus}
                                                                value={secondBetAmount}
                                                                onChange={handleSecondBetAmountChange}
                                                                onBlur={verifySecondBetAmountChange}
                                                            />
                                                            <button onClick={secondBetAddBet} disabled={secondBetStatus} size="sm" className="h-5 w-6 m-0.5 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">+</button>
                                                        </div>
                                                    </div>
                                                    <button onClick={secondBetMultiplyBet} disabled={secondBetStatus} size="sm" className="w-8 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">x2</button>
                                                </div>


                                                {/* <div className={"flex items-stretch gap-2 mt-2 m-0.5" + ((secondBetStatus === false) ? "" : " opacity-50")}>
                                        <button onClick={secondBetSetAddValue} data-value={firstPresetInput} disabled={secondBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{firstPresetInput}</button>
                                        <button onClick={secondBetSetAddValue} data-value={secondPresetInput} disabled={secondBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{secondPresetInput}</button>
                                        <button onClick={secondBetSetAddValue} data-value={thirdPresetInput} disabled={secondBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{thirdPresetInput}</button>
                                        <button onClick={secondBetSetAddValue} data-value={fourthPresetInput} disabled={secondBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{fourthPresetInput}</button>
                                    </div>
                                    <div className={"flex items-stretch gap-2 mt-2 m-0.5 text-center" + ((secondBetStatus === false) ? "" : " opacity-50")}>
                                        <button onClick={secondBetSetAddValue} data-value={fifthPresetInput} disabled={secondBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{fifthPresetInput}</button>
                                        <button onClick={secondBetSetAddValue} data-value={sixthPresetInput} disabled={secondBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{sixthPresetInput}</button>
                                        <button onClick={secondBetSetAddValue} data-value={seventhPresetInput} disabled={secondBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{seventhPresetInput}</button>
                                        <button onClick={secondBetReset} disabled={secondBetStatus} size="sm" className=" flex items-center justify-center p-1 w-full text-gray-50 text-xs rounded bg-[#b11b1b] border border-[#b11b1b] hover:bg-[#0f214d] hover:border-[#ca3f43] hover:text-[#ca3f43] active:border-0 transition duration-700 ease-in-out">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.0" stroke="currentColor" className="w-4 h-3 ">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                            </svg>
                                        </button>
                                    </div> */}


                                                <div className={"flex items-stretch gap-2 mt-2 m-0.5 text-center" + ((secondBetStatus === false) ? "" : " opacity-50")}>
                                                    <button onClick={secondBetSetAddValue} data-value={firstPresetInput} disabled={secondBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{firstPresetInput}</button>
                                                    <button onClick={secondBetSetAddValue} data-value={secondPresetInput} disabled={secondBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{secondPresetInput}</button>
                                                    <button onClick={secondBetSetAddValue} data-value={thirdPresetInput} disabled={secondBetStatus} size="sm" className="p-1 w-full text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">{thirdPresetInput}</button>
                                                    <button onClick={secondBetReset} disabled={secondBetStatus} size="sm" className=" flex items-center justify-center p-1 w-full text-gray-50 text-xs rounded bg-[#b11b1b] border border-[#b11b1b] hover:bg-[#0f214d] hover:border-[#ca3f43] hover:text-[#ca3f43] active:border-0 transition duration-700 ease-in-out">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.0" stroke="currentColor" className="w-4 h-3 ">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                                        </svg>
                                                    </button>
                                                </div>


                                                <div className="mt-1.5 grid grid-cols-1">
                                                    <div className={"grid grid-cols-4" + ((secondBetStatus === false) ? "" : " opacity-50")}>
                                                        <div className=" col-span-2 ">
                                                            <div className="mr-2  space-x-2 flex items-stretch max-h-5">
                                                                <span className="leading-5 text-xs text-gray-100 ">
                                                                    Auto
                                                                </span>
                                                                <span className="">
                                                                    <Switch
                                                                        className='h-3 mt-0.5 w-5 bg-[#2e335c]' id="secondBetSwitch" color="orange" ripple={true}
                                                                        containerProps={{
                                                                            className: "",
                                                                        }}
                                                                        circleProps={{
                                                                            className: "h-3 w-3 ",
                                                                        }}
                                                                        name="secondBetAutoCashout"
                                                                        disabled={secondBetStatus}
                                                                        // value={secondBetAutoCashout}
                                                                        onChange={handleSecondBetAutoCashout}
                                                                        checked={secondBetAutoCashout}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-2">
                                                            <div className=" p-px flex items-stretch w-full bg-[#413f8d] rounded">
                                                                <div className={"flex items-stretch w-full  rounded " + (!secondBetAutoCashout ? " opacity-50 bg-[#2e335c]" : " bg-gray-50")}>
                                                                    <button onClick={secondBetSubtractMultiplier} disabled={(!secondBetAutoCashout || secondBetStatus)} size="" className={"leading-[0.50rem] h-4 w-6 m-0.5 rounded text-gray-50 text-xs bg-[#2e335c] border border-[#413f8d]  active:border-0 transition duration-700 ease-in-out" + (!secondBetAutoCashout ? "" : " hover:bg-[#f06514] hover:border-[#f06514]")}>-</button>
                                                                    <input
                                                                        type="number"
                                                                        className={"pt-1 text-center w-full  font-medium text-xs bg-transparent outline-none " + (!secondBetAutoCashout ? "text-gray-500" : "text-[#151937]")}
                                                                        id="secondBetMultiplier"
                                                                        disabled={(!secondBetAutoCashout || secondBetStatus)}
                                                                        value={secondBetMultiplier}
                                                                        onChange={handleSecondBetMultiplierChange}
                                                                        onBlur={verifySecondBetMultiplierChange}
                                                                    />
                                                                    <button onClick={secondBetAddMultiplier} disabled={(!secondBetAutoCashout || secondBetStatus)} size="" className={"leading-[0.50rem] h-4 w-6 m-0.5 rounded text-gray-50 text-xs bg-[#2e335c] border border-[#413f8d]  active:border-0 transition duration-700 ease-in-out" + (!secondBetAutoCashout ? "" : " hover:bg-[#f06514] hover:border-[#f06514]")}>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="mt-1.5 grid grid-cols-1">
                                                    <div className="ml-2 mt-1.5">
                                                        {secondBetRoundId === 0 &&
                                                            //  <button onClick={handleSecondBetButtonClick} disabled={secondBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded bg-[#7aa82a] border  border-[#7aa82a] hover:bg-[#49c92c] hover:border-[#49c92c] active:border-0 transition duration-700 ease-in-out"> 
                                                            //  <button onClick={handleSecondBetButtonClick} disabled={secondBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded bg-[#0652dd] border  border-[#0652dd] hover:bg-[#49c92c] hover:border-[#49c92c] active:border-0 transition duration-700 ease-in-out"> 
                                                            //  <button onClick={handleSecondBetButtonClick} disabled={secondBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded bg-[#49c92c] border  border-[#49c92c] hover:bg-[#f7a026] hover:border-[#f7a026] active:border-0 transition duration-700 ease-in-out">
                                                            //  <button onClick={handleSecondBetButtonClick} disabled={secondBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded bg-[#f06514] border  border-[#f06514] hover:bg-[#ca3f43] hover:border-[#ca3f43] active:border-0 transition duration-700 ease-in-out">
                                                            //   <button onClick={handleSecondBetButtonClick} disabled={secondBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded bg-[#f7a026] border  border-[#f7a026] hover:bg-[#49c92c] hover:border-[#49c92c] active:border-0 transition duration-700 ease-in-out">
                                                            <button onClick={handleSecondBetButtonClick} disabled={secondBetProcessing} size="lg" className="p-3 w-full h-full font-medium  text-gray-50 text-xs rounded bg-[#ac3090] border  border-[#ac3090] hover:bg-[#49c92c] hover:border-[#49c92c] active:border-0 transition duration-700 ease-in-out">

                                                                {secondBetProcessing === false &&
                                                                    <span>
                                                                        PLACE A BET
                                                                    </span>
                                                                }
                                                                {secondBetProcessing === true &&
                                                                    <span className="flex items-center justify-center gap-2 mx-auto">
                                                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                        </svg>
                                                                        Processing...
                                                                    </span>
                                                                }
                                                            </button>
                                                        }
                                                        {(secondBetRoundId > 0) && (roundId !== secondBetRoundId) && (delay === null) &&
                                                            <button onClick={handleSecondBetButtonCancel} disabled={secondBetProcessing} size="lg" className="p-3 w-full h-full text-gray-300 bg-gradient-to-b from-red-600 via-red-800 to-red-900 shadow-red hover:shadow-hoverRed text-xs font-medium rounded active:bg-red-800 active:shadow">
                                                                {secondBetProcessing === false &&
                                                                    <span>
                                                                        CANCEL
                                                                    </span>
                                                                }
                                                                {secondBetProcessing === true &&
                                                                    <span className="flex items-center justify-center gap-2 mx-auto">
                                                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                        </svg>
                                                                        Processing...
                                                                    </span>
                                                                }
                                                            </button>
                                                        }
                                                        {(roundId === secondBetRoundId) && (secondBetRoundId > 0) &&
                                                            <button onClick={handleSecondBetButtonCashout} disabled={secondBetProcessing} size="lg" className="p-3 w-full h-full font-bold  text-[#111111] text-xs rounded  bg-[#49c92c] border border-[#58c92c] hover:border-[#ca3f43] hover:bg-[#f06514]  active:border-0 transition duration-700 ease-in-out">
                                                                {secondBetProcessing === false &&
                                                                    <span>
                                                                        TAKE WINNINGS {formatAmount(secondBet)}
                                                                    </span>
                                                                }
                                                                {secondBetProcessing === true &&
                                                                    <span className="flex items-center justify-center gap-2 mx-auto">
                                                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                        </svg>
                                                                        Processing...
                                                                    </span>
                                                                }
                                                            </button>
                                                        }
                                                        {(secondBetRoundId > 0) && (roundId !== secondBetRoundId) && (delay !== null) &&
                                                            <button size="lg" className="p-3 w-full h-full text-gray-100 bg-gradient-to-b from-gray-400 via-gray-600 to-gray-700 shadow-gray hover:shadow-hoverGray text-xs font-medium rounded active:bg-gray-800 active:shadow cursor-not-allowed opacity-50" disabled>
                                                                {secondBetProcessing === false &&
                                                                    <span>
                                                                        TAKE WINNINGS
                                                                    </span>
                                                                }
                                                                {secondBetProcessing === true &&
                                                                    <span className="flex items-center justify-center gap-2 mx-auto">
                                                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                        </svg>
                                                                        Processing...
                                                                    </span>
                                                                }
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel className="m-0 p-0" key="autoplay" value="autoplay">



                                <div>
                                    <div className="grid grid-cols-1 gap-2">
                                        <div className={"grid grid-cols-1" + ((autoplayStatus === false) ? "" : " opacity-50")}>
                                            <div className="grid grid-cols-2 gap-2 md:gap-0 my-1">
                                                <div>
                                                    <div className="text-xs text-gray-500 my-0">Base Bet</div>
                                                    <div className="p-px flex items-stretch w-full bg-[#413f8d] rounded">
                                                        <div className="flex items-stretch w-full bg-gray-50 rounded">
                                                            <button onClick={autoplaySubtractBet} disabled={autoplayStatus} size="sm" className="h-5 w-6 m-0.5 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">-</button>
                                                            <input
                                                                type="number"
                                                                className="pt-1 text-center w-full text-[#151937] text-xs font-medium bg-transparent outline-none"
                                                                id="baseBet-mel"
                                                                name="baseBet"
                                                                disabled={autoplayStatus}
                                                                value={autoplayAmount}
                                                                onChange={handleAutoplayAmountChange}
                                                                onBlur={verifyAutoplayAmountChange}
                                                            />
                                                            <button onClick={autoplayAddBet} disabled={autoplayStatus} size="sm" className="h-5 w-6 m-0.5 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">+</button>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div>
                                                    <div className="text-xs text-gray-500 my-0">Maximum Stake Amount</div>
                                                    <div className="p-px flex items-stretch w-full bg-[#413f8d] rounded">
                                                        <div className="flex items-stretch w-full bg-gray-50 rounded">
                                                            <button onClick={autoplaySubtractMaximumStakeAmount} disabled={(autoplayStatus)} size="" className="h-5 w-6 m-0.5 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">-</button>
                                                            <input
                                                                type="number"
                                                                className="pt-1 text-center w-full text-[#151937] text-xs font-medium bg-transparent outline-none"
                                                                id="autoplayStopIfLossInput-mel"
                                                                disabled={(autoplayStatus)}
                                                                value={maximumStakeAmount}
                                                                onChange={handleMaximumStakeAmountChange}
                                                                onBlur={verifyMaximumStakeAmountChange}
                                                            />
                                                            <button onClick={autoplayAddMaximumStakeAmount} disabled={(autoplayStatus)} size="" className="h-5 w-6 m-0.5 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">+</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='my-1'>
                                                <div className="text-xs text-gray-500 my-0">Auto Cashout</div>
                                                <div className="p-px flex items-stretch w-full bg-[#413f8d] rounded">
                                                    <div className="flex items-stretch w-full bg-gray-50 rounded">
                                                        <button onClick={autoplaySubtractMultiplier} disabled={autoplayStatus} size="sm" className="h-5 w-6 m-0.5 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">-</button>
                                                        <input
                                                            type="number"
                                                            className="pt-1 text-center w-full text-[#151937] text-xs font-medium bg-transparent outline-none"
                                                            id="autoplayMultiplier-sm-mel"
                                                            disabled={autoplayStatus}
                                                            value={autoplayMultiplier}
                                                            onChange={handleAutoplayMultiplierChange}
                                                            onBlur={verifyAutoplayMultiplierChange}
                                                        />
                                                        <button onClick={autoplayAddMultiplier} disabled={autoplayStatus} size="sm" className="h-5 w-6 m-0.5 text-gray-50 text-xs rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">+</button>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="grid grid-cols-2 gap-2">
                                                <div>
                                                    <div className="text-xs text-[#afff24] my-0">If You Win</div>
                                                    <div className="mb-1 p-px bg-[#413f8d] rounded">
                                                        <div className="grid grid-cols-1 bg-[#2e335c] rounded ">
                                                            <Radio
                                                                id="onWinBaseBet-mel" label="Base&nbsp;Bet" name="onWinMel" color="green"
                                                                className="h-3"
                                                                icon={<svg width="10px" height="10px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#afff24"></path> </g></svg>}
                                                                labelProps={{
                                                                    className: "text-xs text-gray-50",
                                                                }}
                                                                iconProps={{
                                                                    className: "",
                                                                }}
                                                                containerProps={{
                                                                    className: "w-[20px] px-1 py-0",
                                                                }}
                                                                onChange={handleAutoplayOnWin}
                                                                checked={!autoplayOnWinIncrease}

                                                            />
                                                            <Radio
                                                                id="onWinIncrease-mel" label="Increase" name="onWinMel" color="green"
                                                                className="h-3"

                                                                icon={<svg width="10px" height="10px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#afff24"></path> </g></svg>}

                                                                labelProps={{
                                                                    className: "text-xs text-gray-50",
                                                                }}
                                                                iconProps={{
                                                                    className: "",
                                                                }}
                                                                containerProps={{
                                                                    className: "w-[20px] px-1 py-0",
                                                                }}
                                                                onChange={handleAutoplayOnWin}
                                                                checked={autoplayOnWinIncrease}
                                                            />

                                                            <div className=" p-px flex items-stretch w-full bg-[#413f8d] rounded">
                                                                <div className={"flex items-stretch w-full  rounded " + (!autoplayOnWinIncrease ? " opacity-50 bg-[#2e335c]" : " bg-gray-50")}>
                                                                    <button onClick={autoplayOnWinSubtractMultiplier} disabled={(!autoplayOnWinIncrease || autoplayStatus)} size="" className={"leading-[0.50rem] h-4 w-6 m-0.5 rounded text-gray-50 text-xs bg-[#2e335c] border border-[#413f8d]  active:border-0 transition duration-700 ease-in-out " + (!autoplayOnWinIncrease ? "" : " hover:bg-[#f06514] hover:border-[#f06514]")}>-</button>
                                                                    <input
                                                                        type="text"
                                                                        className={"pt-1 text-center w-full  font-medium text-xs bg-transparent outline-none " + (!autoplayOnWinIncrease ? "text-gray-500" : "text-[#151937]")}
                                                                        id="autoplayOnWinMultiplier-mel"
                                                                        disabled={(!autoplayOnWinIncrease || autoplayStatus)}
                                                                        value={'x' + autoplayOnWinMultiplier}
                                                                        onChange={handleAutoplayOnWinMultiplierChange}
                                                                        onBlur={verifyAutoplayOnWinMultiplierChange}
                                                                    />
                                                                    <button onClick={autoplayOnWinAddMultiplier} disabled={(!autoplayOnWinIncrease || autoplayStatus)} size="" className={"leading-[0.50rem] h-4 w-6 m-0.5 rounded text-gray-50 text-xs bg-[#2e335c] border border-[#413f8d]  active:border-0 transition duration-700 ease-in-out " + (!autoplayOnWinIncrease ? "" : " hover:bg-[#f06514] hover:border-[#f06514]")}>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div>
                                                    <div className="text-xs text-[#ff292c] my-0">If You Lose</div>
                                                    <div className="mb-1 p-px bg-[#413f8d] rounded">
                                                        <div className="grid grid-cols-1 bg-[#2e335c] rounded ">
                                                            <Radio
                                                                id="onLossBaseBet-sm-mel" label="Base&nbsp;Bet" name="onLoss-sm-mel" color="red"
                                                                className="h-3"
                                                                icon={<svg width="10px" height="10px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#ff292c"></path> </g></svg>}
                                                                labelProps={{
                                                                    className: "text-xs text-gray-50",
                                                                }}
                                                                iconProps={{
                                                                    className: "",
                                                                }}
                                                                containerProps={{
                                                                    className: "w-[20px] px-1 py-0",
                                                                }}
                                                                onChange={handleAutoplayOnLoss}
                                                                checked={!autoplayOnLossIncrease}

                                                            />
                                                            <Radio
                                                                id="onLossIncrease-sm-mel" label="Increase" name="onLoss-sm-mel" color="red"
                                                                className="h-3"

                                                                icon={<svg width="10px" height="10px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#ff292c"></path> </g></svg>}

                                                                labelProps={{
                                                                    className: "text-xs text-gray-50",
                                                                }}
                                                                iconProps={{
                                                                    className: "",
                                                                }}
                                                                containerProps={{
                                                                    className: "w-[20px] px-1 py-0",
                                                                }}
                                                                onChange={handleAutoplayOnLoss}
                                                                checked={autoplayOnLossIncrease}
                                                            />
                                                            <div className=" p-px flex items-stretch w-full bg-[#413f8d] rounded">
                                                                <div className={"flex items-stretch w-full  rounded " + (!autoplayOnLossIncrease ? " opacity-50 bg-[#2e335c]" : " bg-gray-50")}>
                                                                    <button onClick={autoplayOnLossSubtractMultiplier} disabled={(!autoplayOnLossIncrease || autoplayStatus)} size="" className={"leading-[0.50rem] h-4 w-6 m-0.5 rounded text-gray-50 text-xs bg-[#2e335c] border border-[#413f8d]  active:border-0 transition duration-700 ease-in-out " + (!autoplayOnLossIncrease ? "" : " hover:bg-[#f06514] hover:border-[#f06514]")}>-</button>
                                                                    <input
                                                                        type="text"
                                                                        className={"pt-1 text-center w-full  font-medium text-xs bg-transparent outline-none " + (!autoplayOnLossIncrease ? "text-gray-500" : "text-[#151937]")}
                                                                        id="autoplayOnLossMultiplier-sm-mel"
                                                                        disabled={(!autoplayOnLossIncrease || autoplayStatus)}
                                                                        value={'x' + autoplayOnLossMultiplier}
                                                                        onChange={handleAutoplayOnLossMultiplierChange}
                                                                        onBlur={verifyAutoplayOnLossMultiplierChange}
                                                                    />
                                                                    <button onClick={autoplayOnLossAddMultiplier} disabled={(!autoplayOnLossIncrease || autoplayStatus)} size="" className={"leading-[0.50rem] h-4 w-6 m-0.5 rounded text-gray-50 text-xs bg-[#2e335c] border border-[#413f8d]  active:border-0 transition duration-700 ease-in-out " + (!autoplayOnLossIncrease ? "" : " hover:bg-[#f06514] hover:border-[#f06514]")}>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>






                                        </div>

                                    </div>
                                    <div className="grid grid-cols-1 place-items-center pb-0.5 mt-1">
                                        <span className="flex items-stretch w-full pt-1">
                                            {((autoplayStatus === false) && (delay === null)) &&
                                                // <button onClick={handleAutoplayBetButton} disabled={autoplayProcessing} size="md" className="mx-1 p-3 w-full font-medium  text-gray-50 text-xs rounded  bg-[#7aa82a] border  border-[#7aa82a] hover:bg-[#49c92c] hover:border-[#49c92c]  active:border-0 transition duration-700 ease-in-out"> 
                                                // <button onClick={handleAutoplayBetButton} disabled={autoplayProcessing} size="md" className="mx-1 p-3 w-full font-medium  text-gray-50 text-xs rounded  bg-[#0652dd] border  border-[#0652dd] hover:bg-[#49c92c] hover:border-[#49c92c]  active:border-0 transition duration-700 ease-in-out"> 
                                                // <button onClick={handleAutoplayBetButton} disabled={autoplayProcessing} size="md" className="mx-1 p-3 w-full font-medium  text-gray-50 text-xs rounded  bg-[#49c92c] border  border-[#49c92c] hover:bg-[#f7a026] hover:border-[#f7a026]  active:border-0 transition duration-700 ease-in-out">
                                                //  <button onClick={handleAutoplayBetButton} disabled={autoplayProcessing} size="md" className="mx-1 p-3 w-full font-medium  text-gray-50 text-xs rounded bg-[#f06514] border border-[#f06514] hover:bg-[#ca3f43] hover:border-[#ca3f43] active:border-0 transition duration-700 ease-in-out">
                                                //  <button onClick={handleAutoplayBetButton} disabled={autoplayProcessing} size="md" className="mx-1 p-3 w-full font-medium  text-gray-50 text-xs rounded  bg-[#f7a026] border  border-[#f7a026] hover:bg-[#49c92c] hover:border-[#49c92c]  active:border-0 transition duration-700 ease-in-out">
                                                <button onClick={handleAutoplayBetButton} disabled={autoplayProcessing} size="md" className="mx-1 p-3 w-full font-medium  text-gray-50 text-xs rounded  bg-[#ac3090] border  border-[#ac3090] hover:bg-[#49c92c] hover:border-[#49c92c]  active:border-0 transition duration-700 ease-in-out">


                                                    {autoplayProcessing === false &&
                                                        <span>
                                                            START AUTOPLAY
                                                        </span>
                                                    }
                                                    {autoplayProcessing === true &&
                                                        <span className="flex items-center justify-center gap-2 mx-auto">
                                                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            Processing...
                                                        </span>
                                                    }
                                                </button>
                                            }
                                            {((autoplayStatus === true) && (delay === null)) &&
                                                <button onClick={handleAutoplayCancelButton} disabled={autoplayProcessing} size="md" className="mx-1 p-3 w-full text-gray-300 bg-gradient-to-b from-red-600 via-red-800 to-red-900 shadow-red hover:shadow-hoverRed text-xs font-medium rounded active:bg-red-800 active:shadow">
                                                    {autoplayProcessing === false &&
                                                        <span>
                                                            CANCEL  {formatAmount(autoplay)}
                                                        </span>
                                                    }
                                                    {autoplayProcessing === true &&
                                                        <span className="flex items-center justify-center gap-2 mx-auto">
                                                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            Processing...
                                                        </span>
                                                    }
                                                </button>
                                            }
                                            {(delay !== null) &&
                                                <button size="md" className="mx-1 p-3 w-full text-gray-300 bg-gradient-to-b from-gray-400 via-gray-600 to-gray-700 shadow-gray hover:shadow-hoverGray text-xs font-medium rounded active:bg-gray-800 active:shadow cursor-not-allowed opacity-50" disabled>
                                                    {autoplayProcessing === false &&
                                                        <span>
                                                            WAITING
                                                        </span>
                                                    }
                                                    {autoplayProcessing === true &&
                                                        <span className="flex items-center justify-center gap-2 mx-auto">
                                                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            Processing...
                                                        </span>
                                                    }
                                                </button>
                                            }

                                            <button onClick={autoplayReset} disabled={autoplayStatus} size="md"
                                                className={"mx-1 p-3 text-gray-50 text-xs rounded bg-[#b11b1b] border border-[#b11b1b] hover:bg-[#0f214d] hover:border-[#ca3f43] hover:text-[#ca3f43] active:border-0 transition duration-700 ease-in-out" + ((autoplayStatus === false) ? "" : " opacity-50")}>
                                                <ArrowUturnLeftIcon className="h-3 w-4 text-white" strokeWidth={2.5} />
                                            </button>
                                        </span>
                                    </div>
                                </div>



                            </TabPanel>

                        </TabsBody>
                    </Tabs>


                </div>
            </>

        </>
    )
}


export default CrashNew;
